const Callout = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-2{fill:#9f9f9f}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Heart">
        <g id="Group_9019" data-name="Group 9019">
          <g id="Rectangle_40-3" data-name="Rectangle 40-3">
            <rect
              width={60}
              height={60}
              rx={4}
              style={{
                fill: "none",
              }}
            />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              className="cls-2"
            />
          </g>
          <g id="Group_9018" data-name="Group 9018">
            <g id="Group_9011-2" data-name="Group 9011-2">
              <path
                id="Line_846-3"
                d="M43 13H11a1 1 0 0 1 0-2h32a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 846-3"
              />
              <path
                id="Line_856-3"
                d="M37 18H11a1 1 0 0 1 0-2h26a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 856-3"
              />
              <path
                id="Line_857-3"
                d="M40 23H11a1 1 0 0 1 0-2h29a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 857-3"
              />
            </g>
            <path
              id="Line_859-2"
              d="M44 44H11a1 1 0 0 1 0-2h33a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 859-2"
            />
            <path
              id="Line_858-2"
              d="M35 49H11a1 1 0 0 1 0-2h24a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 858-2"
            />
            <rect
              id="Rectangle_2450"
              width={40}
              height={7}
              x={10}
              y={29}
              className="cls-2"
              data-name="Rectangle 2450"
              rx={0.5}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Callout;
