import React, { useEffect, useRef, useState } from "react";
import MenuComponent from "./Menu";
import InlineToolbar from "./InlineToolbar";
import { RenderAllNodes } from "../utils";
import { useDBStore } from "../stores/dbStore";
import SubMenuComponent from "./SubMenu";
import {
  completelyInRange,
  findNodeById,
  getAbsolutePosition,
} from "../helpers";
import { rangy } from "../DOM";
import Mention from "./plugins/Mention";

const Editor = () => {
  const {
    setShowInlineTool,
    doRedo,
    doUndo,
    setUndo,
    setInlineToolCords,
    currentPage,
    showInlineTool,
    setShowMention,
    setShowSubMenu,
    createNewBlock,
    setActiveNode,
    setFocus,
    showMenu,
    setPreventkey,
    updateParent,
    deleteBlock,
    deleteNode,
    pages,
    initDB,
  } = useDBStore((state) => state);
  const { activeNode, nodes, blocks } = pages[currentPage];
  const node = nodes[activeNode];
  const editorRef = useRef(null);
  const mouseIsDown = useRef(false);

  const handleKeyDown = (e) => {
    const key = e.key;
    const ctrl = e.ctrlKey || e.metaKey;
    const shift = e.shiftKey;
    const selection = window.getSelection();
    const range = document.createRange();
    const selectedRange =
      selection?.rangeCount > 0 ? selection.getRangeAt(0) : null;
    const isRange = selection.type === "Range";
    const _anchorNode = selection.anchorNode;
    const _anchorOffset = selection.anchorOffset;
    const _focusNode = selection.focusNode;
    const _focusOffset = selection.focusOffset;
    const editable = _anchorNode.parentElement.closest(".nodeWrapper");
    const activeElement = document.activeElement;
    if (activeElement.closest(".inline-tool")) return; // Disable all action when in inlinetool
    const isSelectedAcross =
      isRange &&
      editable &&
      !(editable?.contains(_anchorNode) && editable?.contains(_focusNode));
    if (isSelectedAcross) {
      // Make sure the selection is spawn across more than one Node
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    switch (key.toLowerCase()) {
      case "z":
        if (ctrl && !shift) {
          e.stopImmediatePropagation();
          e.preventDefault();
          doUndo();
          return;
        }
        if (ctrl && shift) {
          e.stopImmediatePropagation();
          e.preventDefault();
          doRedo();
          return;
        }
        break;
      case "y":
        if (ctrl && !shift) {
          e.stopImmediatePropagation();
          e.preventDefault();
          doRedo();
          return;
        }
        break;

      case "escape":
        e.stopImmediatePropagation();
        e.preventDefault();
        setShowMention(null);
        setShowSubMenu(null);
        break;
      case "backspace":
        range.selectNodeContents(editorRef.current);
        if (selectedRange.toString() === range.toString()) {
          /// Select all is present
          initDB();
          createNewBlock();
          return;
        }
        if (!isSelectedAcross) return;
        range.selectNode(_anchorNode);
        const anchorRange = range.cloneRange();
        range.selectNode(_focusNode);
        const contents = selectedRange.cloneContents();
        const wrappers = contents.querySelectorAll(".nodeWrapper");
        const isForward =
          range.compareBoundaryPoints(Range.START_TO_END, anchorRange) === 1;
        let startContainer;
        let anchorNode;
        let startOffset;
        let endContainer;
        let focusNode;
        let endOffset;
        const anchorAncestor =
          anchorRange.commonAncestorContainer.closest("[contenteditable]");
        const focusAncestor =
          range.commonAncestorContainer.closest("[contenteditable]");

        if (isForward) {
          startContainer = anchorAncestor;
          endContainer = focusAncestor;
          startOffset = _anchorOffset;
          anchorNode = _anchorNode;
          endOffset = _focusOffset;
          focusNode = _focusNode;
        } else {
          startContainer = focusAncestor;
          endContainer = anchorAncestor;
          startOffset = _focusOffset;
          anchorNode = _focusNode;
          endOffset = _anchorOffset;
          focusNode = _anchorNode;
        }
        selection.removeAllRanges();
        range.selectNodeContents(startContainer);
        range.setStart(anchorNode, startOffset);
        range.deleteContents();
        range.selectNodeContents(endContainer);
        range.setEnd(focusNode, endOffset);
        range.deleteContents();
        range.selectNodeContents(startContainer);
        selection.addRange(range);
        selection.collapseToEnd();
        startContainer.insertAdjacentHTML("beforeend", endContainer.innerHTML);
        const endContainerId = endContainer
          .closest(".nodeWrapper")
          .getAttribute("data-id");
        updateParent(endContainerId);
        deleteBlock(endContainerId);
        deleteNode(endContainerId);
        wrappers.forEach((wrapper) => {
          const id = wrapper.getAttribute("data-id");
          const ele = findNodeById(id);
          if (completelyInRange(selectedRange, ele)) {
            updateParent(id);
            deleteBlock(id);
            deleteNode(id);
          }
        });
        break;
      case "a":
        if (ctrl && !shift) {
          e.stopImmediatePropagation();
          e.preventDefault();
          range.selectNodeContents(activeElement);
          const selectedAll = selectedRange.toString() === range.toString();
          if (selectedAll || activeElement.textContent === "") {
            let allEditables =
              editorRef.current.querySelectorAll(".nodeWrapper");
            allEditables = Array.from(allEditables);
            const first = allEditables[0];
            const last = allEditables.splice(-1)[0];
            range.selectNodeContents(last);
            range.setStart(first, 0);
            selection.removeAllRanges();
            selection.addRange(range);
            return;
          }
          selection.selectAllChildren(activeElement);
        }
        break;
      default:
        break;
    }
  };

  // const handleMouseDown = ({ target }) => {
  //   target.closest("#editor")?.setAttribute("contenteditable", true);
  //   setShowInlineTool(null);
  // };

  const handleSelectionChange = () => {
    const sel = window.getSelection();
    const range = sel.rangeCount > 0 ? sel.getRangeAt(0) : null;
    if (
      sel.type !== "Range" &&
      !range?.commonAncestorContainer.parentElement.closest(".inline-tool")
    ) {
      const span = document.querySelector("span.selected-portion");
      span?.replaceWith(span.innerHTML);
      setShowInlineTool(null);
    }
  };

  const handleMouseUp = (e) => {
    const target = e.target;
    const id = target.closest(".nodeWrapper")?.getAttribute("data-id");
    const selection = window.getSelection();
    const selectedRange =
      selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    editorRef.current?.setAttribute("contenteditable", false);
    if (target.closest(".inline-tool")) return;
    setShowInlineTool(null);
    if (
      selection.type === "Range" &&
      selectedRange &&
      target.closest("#editor")
    ) {
      try {
        const selObj = rangy.getSelection();
        const cursor = rangy.serializeSelection(
          selObj,
          true,
          editorRef.current
        );
        setUndo({
          node: { text: target.innerHTML },
          focusedId: id,
          cursor,
        });
      } catch (error) {
        console.log(error);
      }
      const cords = getAbsolutePosition(selectedRange);
      setInlineToolCords(cords);
      setShowInlineTool(true);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    const target = e.target;
    const lastHblockId = blocks.slice(-1)[0];
    const hBlock = nodes[lastHblockId];
    if (!hBlock) return;
    if (hBlock.children?.length === 1) {
      const child = nodes[hBlock.children[0]];
      if (child.children?.length === 1) {
        const gChild = nodes[child.children[0]];
        const gChildElement = findNodeById(
          gChild.id,
          target.previousElementSibling?.previousElementSibling
        );
        if (gChild.type === "paragraph" && gChildElement?.textContent === "") {
          // Focus on it
          setFocus(null);
          setActiveNode(null); // For rerendering purposes
          setFocus(gChild.id);
          setActiveNode(gChild.id);
          gChildElement?.focus();
          return;
        }
      }
    }
    createNewBlock("paragraph", blocks.length);
  };

  useEffect(() => {
    document.removeEventListener("mouseup", handleMouseUp);
    document.addEventListener("mouseup", handleMouseUp);
    document.removeEventListener("keydown", handleKeyDown, true);
    document.addEventListener("keydown", handleKeyDown, true);
    document.addEventListener("selectionchange", handleSelectionChange, true);
  }, []);
  return (
    <div className="h-full">
      <div id="editor" className="h-full" ref={editorRef}>
        <RenderAllNodes />
        <div className="w-full h-16 select-none" onClick={handleClick}></div>
      </div>
      <Mention />
      <MenuComponent />
      <SubMenuComponent />
      <InlineToolbar />
    </div>
  );
};

export default Editor;
