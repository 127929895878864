import { Fragment, useEffect, useState } from "react";
import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";
import { RxDotFilled } from "react-icons/rx";
import { findNodeById } from "../../helpers";

export function UList({ id, renderer }) {
  const { pages, currentPage } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];
  const children = node.children;
  return (
    <>
      <div className="flex w-full">
        <span className="pt-1.5">
          <RxDotFilled />
        </span>
        <ContentEditable
          id={id}
          className="outline-none text-normal px-1 my-0.5 min-w-full min-h-5 inline-block"
          aria-placeholder="List"
        />
      </div>
      <div className="pl-2 w-full">
        {children.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
      </div>
    </>
  );
}

export function OList({ id, renderer }) {
  const { pages, currentPage } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];
  const [listIndex, setListIndex] = useState("");
  const children = node.children;
  const parent = nodes[node.parent];

  useEffect(() => {
    const alphabets =
      "a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z";

    const romanToInt = (roman) => {
      const romanMap = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
      let int = 0;
      if (!roman) {
        return null;
      }
      for (let i = 0; i < roman.length; i++) {
        if (!romanMap[roman[i]]) break;
        const current = romanMap[roman[i]];
        const next = romanMap[roman[i + 1]];

        if (next > current) {
          int += next - current; // Subtractive notation
          i++; // Skip the next character
        } else {
          int += current;
        }
      }

      return int === 0 ? null : int;
    };

    const intToRoman = (num) => {
      const romanNumerals = [
        ["M", 1000],
        ["CM", 900],
        ["D", 500],
        ["CD", 400],
        ["C", 100],
        ["XC", 90],
        ["L", 50],
        ["XL", 40],
        ["X", 10],
        ["IX", 9],
        ["V", 5],
        ["IV", 4],
        ["I", 1],
      ];

      let roman = "";

      for (const [symbol, value] of romanNumerals) {
        while (num >= value) {
          roman += symbol;
          num -= value;
        }
      }

      return roman;
    };
    const isParentListAlphabet = () => {
      const parentDOM = findNodeById(parent.id);
      return (
        parent.type === "olist" &&
        alphabets
          .split(",")
          .includes(parentDOM?.getAttribute("data-list-index"))
      ); // If the parent index is an Alphabet
    };

    const nextIndex = (input) => {
      const alpha = alphabets.split(",");
      let i = alpha.indexOf(input);
      if (i !== -1) {
        i = i > 24 ? 0 : i + 1;
        return alpha[i];
      } else if (isParentListAlphabet()) {
        return intToRoman(romanToInt(input) + 1);
      }
      return parseInt(input) + 1;
    };

    let index = parent.children?.indexOf(id);
    if (index > 0) {
      index -= 1;
      const prevSId = parent.children[index];
      const prevSibling = nodes[prevSId];
      if (prevSibling?.type === "olist") {
        const pNode = findNodeById(prevSId);
        index = nextIndex(pNode.getAttribute("data-list-index"));
        setListIndex(index);
      } else {
        parent.type === "olist"
          ? isParentListAlphabet()
            ? setListIndex("I")
            : setListIndex("a")
          : setListIndex("1");
      }
    } else {
      parent.type === "olist"
        ? isParentListAlphabet()
          ? setListIndex("I")
          : setListIndex("a")
        : setListIndex("1");
    }
  }, [listIndex]);
  return (
    <Fragment key={id}>
      <div key={`top-${id}`} className="flex items-center w-full">
        <span>{listIndex}.</span>
        <ContentEditable
          id={id}
          data-list-index={listIndex}
          className="outline-none text-normal px-1.5 my-0.5 min-w-full min-h-5 inline-block"
          aria-placeholder="List"
        />
      </div>
      <div key={`bottom-${id}`} className="pl-4 w-full">
        {children.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
      </div>
    </Fragment>
  );
}
