const Page = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-3{fill:#3c3b3b}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Heart">
        <g id="Group_9062" data-name="Group 9062">
          <g id="Rectangle_40-12" data-name="Rectangle 40-12">
            <rect
              width={60}
              height={60}
              rx={4}
              style={{
                fill: "none",
              }}
            />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              style={{
                fill: "#9f9f9f",
              }}
            />
          </g>
          <g id="Group_9061" data-name="Group 9061">
            <g id="Group_9039-6" data-name="Group 9039-6">
              <path
                id="Line_846-11"
                d="M38.87 31.5h-18a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2Z"
                className="cls-3"
                data-name="Line 846-11"
              />
              <path
                id="Line_847-9"
                d="M33.87 37.5h-13a1 1 0 0 1 0-2h13a1 1 0 1 1 0 2Z"
                className="cls-3"
                data-name="Line 847-9"
              />
              <path
                id="Line_855-8"
                d="M36.87 43.5h-16a1 1 0 0 1 0-2h16a1 1 0 1 1 0 2Z"
                className="cls-3"
                data-name="Line 855-8"
              />
            </g>
            <g id="Path_3187" data-name="Path 3187">
              <path
                d="M44.39 22.78a.25.25 0 0 0-.08-.14L31.8 10.09a.21.21 0 0 0-.13-.07H17a2 2 0 0 0-2 2V48a2 2 0 0 0 2 2h25.42a2 2 0 0 0 2-2V22.78Zm-2 26.61H17A1.39 1.39 0 0 1 15.61 48V12A1.38 1.38 0 0 1 17 10.62h14.3v10.52a2 2 0 0 0 2 2h10.48V48a1.36 1.36 0 0 1-1.36 1.39Z"
                className="cls-3"
              />
              <path
                d="M17 50.88A2.89 2.89 0 0 1 14.13 48V12A2.87 2.87 0 0 1 17 9.13h14.89a1.16 1.16 0 0 1 .52.28L44.92 22a1.08 1.08 0 0 1 .31.57V48a2.87 2.87 0 0 1-2.84 2.86Zm0-39.39a.49.49 0 0 0-.49.49V48a.49.49 0 0 0 .49.49h25.42a.49.49 0 0 0 .49-.49V24h-9.65a2.88 2.88 0 0 1-2.86-2.85v-9.66Zm15.17.19v9.46a1.12 1.12 0 0 0 1.11 1.11h9.46Z"
                className="cls-3"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Page;
