const Todo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-1{fill:none}.cls-2{fill:#9f9f9f}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Heart">
        <g id="Group_9041" data-name="Group 9041">
          <g id="Rectangle_40-5" data-name="Rectangle 40-5">
            <rect width={60} height={60} className="cls-1" rx={4} />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              className="cls-2"
            />
          </g>
          <g id="Group_9040" data-name="Group 9040">
            <g id="Group_9039" data-name="Group 9039">
              <path
                id="Line_846-4"
                d="M53 25H35a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 846-4"
              />
              <path
                id="Line_847-2"
                d="M50 31H35a1 1 0 0 1 0-2h15a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 847-2"
              />
              <path
                id="Line_855"
                d="M51 37H35a1 1 0 0 1 0-2h16a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 855"
              />
            </g>
            <g id="Group_9038" data-name="Group 9038">
              <path
                id="Path_8731"
                d="M16 34.22a1.29 1.29 0 0 1-.89-.36l-2.52-2.54a1.24 1.24 0 1 1 1.76-1.76L16 31.2l4.59-4.59a1.25 1.25 0 1 1 1.76 1.77l-5.47 5.48a1.25 1.25 0 0 1-.88.36Z"
                data-name="Path 8731"
                style={{
                  fill: "#222",
                }}
              />
              <g id="Rectangle_2451" data-name="Rectangle 2451">
                <rect
                  width={20}
                  height={20}
                  x={7}
                  y={20}
                  className="cls-1"
                  rx={2}
                />
                <path
                  d="M25 40H9a2 2 0 0 1-2-2V22a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2ZM9.5 37.5h15v-15h-15Z"
                  style={{
                    fill: "#3c3b3b",
                  }}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Todo;
