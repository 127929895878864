import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";

const Heading = ({ id }) => {
  const { pages, currentPage } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];

  const fontSize = (type) => {
    switch (type) {
      case "h1":
        return "34px";
      case "h2":
        return "30px";
      case "h3":
        return "24px";
      default:
        return "34px";
    }
  };
  return (
    <ContentEditable
      id={id}
      style={{ fontSize: fontSize(node.element) }}
      className="heading outline-none font-semibold p-1.5 min-w-full inline-block"
      aria-placeholder={
        node.element === "h1"
          ? "Heading 1"
          : node.element === "h2"
          ? "Heading 2"
          : "Heading 3"
      }
    />
  );
};

export default Heading;
