const Text = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-3{isolation:isolate}.cls-4{fill:#3c3b3b}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_13" data-name="Layer 13">
        <g id="Rectangle_40-3" data-name="Rectangle 40-3">
          <rect
            width={60}
            height={60}
            rx={4}
            style={{
              fill: "none",
            }}
          />
          <path
            d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
            style={{
              fill: "#9f9f9f",
            }}
          />
        </g>
        <g id="Aa" className="cls-3">
          <g className="cls-3">
            <path
              d="M26.11 36h-8.27l-1.72 5h-3.86l8.06-21.33h3.33L31.72 41h-3.87Zm-7.23-3h6.19L22 24.2ZM44.43 41a2.83 2.83 0 0 1-.24-.66c-.07-.25-.12-.53-.17-.82a7.52 7.52 0 0 1-.78.69 5.62 5.62 0 0 1-1 .56 5.68 5.68 0 0 1-2.42.52 6.35 6.35 0 0 1-2.18-.36 5 5 0 0 1-1.71-1 4.8 4.8 0 0 1-1.11-1.5 4.38 4.38 0 0 1-.41-1.88 5 5 0 0 1 .49-2.25 4.26 4.26 0 0 1 1.4-1.64 6.77 6.77 0 0 1 2.27-1 12.26 12.26 0 0 1 3-.34h2.33v-1a2.77 2.77 0 0 0-.69-2 2.69 2.69 0 0 0-2.09-.74 3.41 3.41 0 0 0-1.14.17 2.55 2.55 0 0 0-.87.45 2 2 0 0 0-.56.68 1.9 1.9 0 0 0-.2.85h-3.56a3.8 3.8 0 0 1 .46-1.81 5 5 0 0 1 1.31-1.55 6.61 6.61 0 0 1 2.06-1.09 8.23 8.23 0 0 1 2.7-.41 8.48 8.48 0 0 1 2.47.34 5.38 5.38 0 0 1 1.94 1A4.53 4.53 0 0 1 47 27.93a5.84 5.84 0 0 1 .45 2.38v7a8.11 8.11 0 0 0 .6 3.41V41Zm-3.91-2.56a4 4 0 0 0 2.12-.59 4.08 4.08 0 0 0 .75-.61 3.4 3.4 0 0 0 .51-.69v-3H42a5.23 5.23 0 0 0-3 .69 2.25 2.25 0 0 0-1 2 2.56 2.56 0 0 0 .16.89 1.93 1.93 0 0 0 .49.71 2.17 2.17 0 0 0 .78.47 3.22 3.22 0 0 0 1.09.13Z"
              className="cls-4"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Text;
