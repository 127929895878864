import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { v4 as uuidv4 } from "uuid";
import { Structures } from "../constants";
import {
  findElementByIndex,
  findNodeById,
  findNodeIndex,
  forwardSelection,
  getAbsolutePosition,
  insertAt,
} from "../helpers";
import { rangy } from "../DOM";

export const useDBStore = create(
  immer((set, get) => ({
    initDB: (data = {}) =>
      set((state) => {
        state.pages[get().currentPage] = {};
        state.pages[get().currentPage].id = get().currentPage;
        state.pages[get().currentPage].blocks = [];
        state.pages[get().currentPage].nodes = {};
        state.pages[get().currentPage].undos =
          get().pages[get().currentPage]?.undos ?? [];
        state.pages[get().currentPage].focusedNode = null;
        state.pages[get().currentPage].currentPage = null;
        state.pages[get().currentPage].currentCursor = -1;
        state.pages[get().currentPage].title = data?.title ?? null;
        state.pages[get().currentPage].parent = get().previousPage;
        state.pages[get().currentPage].href = "/Untitled--" + get().currentPage;
      }),
    isEditable: true,
    setEditable: (status) =>
      set((state) => {
        state.isEditable = status;
      }),
    currentPage: null,
    setCurrentPage: (pageId) =>
      set((state) => {
        state.currentPage = pageId;
      }),
    setPreviousPage: (id) =>
      set((state) => {
        state.previousPage = id;
      }),
    history: [],
    setHistory: (history) =>
      set((state) => {
        state.history = history;
      }),
    // nodes: {
    //   "fl-354ee16c-bfdd-44d3-afa9-e93679bda367": {
    //     id: "fl-354ee16c-bfdd-44d3-afa9-e93679bda367",
    //     type: "horizontalBlock",
    //     children: [
    //       "fl-354ee16c-efdd-44d3-afa9-e09679bda367",
    //       "fl-354ee16c-efdd-4883-afa9-e09679bda367",
    //     ],
    //   },
    //   "fl-354ee16c-efdd-44d3-afa9-e09679bda367": {
    //     id: "fl-354ee16c-efdd-44d3-afa9-e09679bda367",
    //     type: "verticalBlock",
    //     parent: "fl-354ee16c-bfdd-44d3-afa9-e93679bda367",
    //     children: ["fl-354ee16c-efdd-fffe-afa9-e0967de3a367"],
    //   },
    //   "fl-354ee16c-efdd-fffe-afa9-e0967de3a367": {
    //     id: "fl-354ee16c-efdd-fffe-afa9-e0967de3a367",
    //     type: "callout",
    //     parent: "fl-354ee16c-efdd-44d3-afa9-e09679bda367",
    //     children: ["fl-354ee16c-efdd-6433-afa9-e09546bda367"],
    //   },

    //   "fl-354ee16c-efdd-6433-afa9-e09546bda367": {
    //     id: "fl-354ee16c-efdd-6433-afa9-e09546bda367",
    //     type: "todo",
    //     parent: "fl-354ee16c-efdd-fffe-afa9-e0967de3a367",
    //     children: [],
    //   },

    //   "fl-354ee16c-efdd-4883-afa9-e09679bda367": {
    //     id: "fl-354ee16c-efdd-4883-afa9-e09679bda367",
    //     type: "verticalBlock",
    //     parent: "fl-354ee16c-bfdd-44d3-afa9-e93679bda367",
    //     children: ["fl-3547616c-efdd-eefe-afa9-e0967de3a367"],
    //   },
    //   "fl-3547616c-efdd-eefe-afa9-e0967de3a367": {
    //     id: "fl-3547616c-efdd-eefe-afa9-e0967de3a367",
    //     type: "callout",
    //     parent: "fl-354ee16c-efdd-4883-afa9-e09679bda367",
    //     children: ["fl-354ee16c-efdd-6433-eda9-e09546bda367"],
    //   },
    //   "fl-354ee16c-efdd-6433-eda9-e09546bda367": {
    //     id: "fl-354ee16c-efdd-6433-eda9-e09546bda367",
    //     type: "list",
    //     parent: "fl-3547616c-efdd-eefe-afa9-e0967de3a367",
    //     children: [
    //       "fl-354ee16c-efdd-6433-4329-e09546bdded7",
    //       "fl-354ee16c-efdd-fff3-4329-e094532dded7",
    //     ],
    //   },
    //   "fl-354ee16c-efdd-6433-4329-e09546bdded7": {
    //     id: "fl-354ee16c-efdd-6433-4329-e09546bdded7",
    //     type: "todo",
    //     parent: "fl-354ee16c-efdd-6433-eda9-e09546bda367",
    //     children: [],
    //   },
    //   "fl-354ee16c-efdd-fff3-4329-e094532dded7": {
    //     id: "fl-354ee16c-efdd-fff3-4329-e094532dded7",
    //     type: "list",
    //     parent: "fl-354ee16c-efdd-6433-eda9-e09546bda367",
    //     children: [],
    //   },

    //   "fl-354ee16c-bfdd-44d3-afa9-e93679b64367": {
    //     id: "fl-354ee16c-bfdd-44d3-afa9-e93679b64367",
    //     type: "horizontalBlock",
    //     children: ["fl-354ee16c-efdd-44d3-afa9-e0967de3a367"],
    //   },
    //   "fl-354ee16c-efdd-44d3-afa9-e0967de3a367": {
    //     id: "fl-354ee16c-efdd-44d3-afa9-e0967de3a367",
    //     type: "verticalBlock",
    //     parent: "fl-354ee16c-bfdd-44d3-afa9-e93679b64367",
    //     children: ["fl-354ee16c-efdd-fffe-afa9-e0967de35437"],
    //   },
    //   "fl-354ee16c-efdd-fffe-afa9-e0967de35437": {
    //     id: "fl-354ee16c-efdd-fffe-afa9-e0967de35437",
    //     text: "",
    //     type: "heading",
    //     element: "h2",
    //     parent: "fl-354ee16c-efdd-44d3-afa9-e0967de3a367",
    //     children: [],
    //   },
    // },
    nodes: {},
    updateNode: (id, data) =>
      set((state) => {
        console.log("Saving...");
        state.pages[get().currentPage].nodes[id] = {
          ...state.pages[get().currentPage].nodes[id],
          ...data,
        };
      }),
    batchUpdate: (data = []) =>
      set((state) => {
        data.map((d) => {
          return (state.pages[get().currentPage].nodes[d.id] = {
            ...state.pages[get().currentPage].nodes[d.id],
            ...d,
          });
        });
      }),

    deleteNode: (id) =>
      set((state) => {
        delete state.pages[get().currentPage].nodes[id];
      }),

    deleteRecursively: (id) => {
      const page = get().pages[get().currentPage];
      const nodes = page.nodes;
      const blocks = page.blocks;
      const node = nodes[id];
      if (node) return;
      get().deleteBlock(id);
      const children = node.children;
      if (children?.length > 0) {
        children?.map((childId) => {
          get().deleteRecursively(childId);
        });
      } else {
        get().deleteNode(id);
      }
    },
    deleteAll: () =>
      set((state) => {
        state.pages[get().currentPage].nodes = {};
        state.pages[get().currentPage].blocks = [];
      }),

    // blocks: [
    //   "fl-354ee16c-bfdd-44d3-afa9-e93679bda367",
    //   "fl-354ee16c-bfdd-44d3-afa9-e93679b64367",
    // ],
    blocks: [],
    updateBlock: (newBlock) =>
      set((state) => {
        state.pages[get().currentPage].blocks = newBlock;
      }),
    deleteBlock: (id) =>
      set((state) => {
        if (!get().pages[get().currentPage].blocks.includes(id)) return;
        state.pages[get().currentPage].blocks = [
          ...get().pages[get().currentPage].blocks.filter((i) => i !== id),
        ];
      }),

    pages: {},
    updatePage: (id, data) =>
      set((state) => {
        state.pages[id] = { ...get().pages[id], ...data };
      }),
    focusedNode: null, //The Node with cursor
    setFocus: (id) =>
      set((state) => {
        state.pages[get().currentPage].focusedNode = id;
      }),

    activeNode: null, // The node that is to be recreated from the menu
    setActiveNode: (id) =>
      set((state) => {
        state.pages[get().currentPage].activeNode = id;
      }),

    focusCords: { top: 0, left: 0, height: 0 }, // The coordinates of the menu buttons top: 0, left: 0, height: 1 menuHeigth: 1
    setFocusCords: (cords) =>
      set((state) => {
        state.focusCords = cords;
      }),
    showMenu: null,
    setShowMenu: (status) =>
      set((state) => {
        state.showMenu = status;
      }),
    showSubMenu: null,
    setShowSubMenu: (status) =>
      set((state) => {
        state.showSubMenu = status;
      }),
    showTurnMenu: null,
    setTurnMenu: (status) =>
      set((state) => {
        state.showTurnMenu = status;
      }),
    showMenuButtons: null,
    setShowMenuButtons: (status) =>
      set((state) => {
        state.showMenuButtons = status;
      }),
    showInlineTool: null,
    setShowInlineTool: (status) =>
      set((state) => {
        state.showInlineTool = status;
      }),
    inlineToolCords: { top: 0, left: 0, height: 0, bottom: 0 }, // The coordinates of the Toolbar buttons top: 0, left: 0, height: 1 menuHeigth: 1
    setInlineToolCords: (cords) =>
      set((state) => {
        state.inlineToolCords = cords;
      }),
    showMention: null,
    setShowMention: (status) =>
      set((state) => {
        state.showMention = status;
      }),

    mentionCords: { top: 0, left: 0, height: 0 }, // The coordinates of the menu buttons top: 0, left: 0, height: 1 menuHeigth: 1
    setMentionCords: (cords) =>
      set((state) => {
        state.mentionCords = cords;
      }),

    draggedNode: null, // The Node currently being dragged
    setDragged: (id) =>
      set((state) => {
        state.draggedNode = id;
      }),

    destinationNode: null, // The Node to drop the drag
    setDestination: (id) =>
      set((state) => {
        state.destinationNode = id;
      }),

    hoverNode: null, // The currently Hovered Node
    setHover: (id) =>
      set((state) => {
        state.hoverNode = id;
      }),
    dragging: false, // Are we dragging?
    setDragging: (status) =>
      set((state) => {
        state.dragging = status;
      }),

    searching: false, // Are we searching?
    setSearching: (status) =>
      set((state) => {
        state.searching = status;
      }),

    keyWords: "", // The Typed word
    setKeywords: (text) =>
      set((state) => {
        state.keyWords = text;
      }),

    updateParent: (id) => {
      const parentId = get().pages[get().currentPage].nodes[id].parent;
      const parent = get().pages[get().currentPage].nodes[parentId];
      const children = [...parent.children.filter((child) => child !== id)];
      if (
        children.length > 0 ||
        ["callout", "toggle", "todo"].includes(parent.type)
      ) {
        get().updateNode(parentId, { children });
        return;
      }
      if (parent.type === "verticalBlock") {
        const pParentId = parent.parent;
        const pParent = get().pages[get().currentPage].nodes[pParentId];
        get().deleteNode(parentId);
        const c = [...pParent.children.filter((i) => i !== parentId)];
        if (c.length === 0) {
          get().deleteBlock(pParentId);
          get().deleteNode(pParentId);
          return;
        }
        get().updateNode(pParentId, { children: c });
      }
    },

    createNewBlock: (
      type = "paragraph",
      index = 0,
      data = {},
      popMenu = false
    ) => {
      const nodeId = data.id ?? "fl-" + uuidv4();
      const vId = "fl-" + uuidv4();
      const hId = "fl-" + uuidv4();

      let node = Structures[type];
      node = typeof node === "function" ? node() : node;
      node = { ...node, id: nodeId, ...data, parent: vId, new: true };
      const vBlock = {
        ...Structures["verticalBlock"],
        ...{ id: vId, children: [nodeId], parent: hId },
      };
      const hBlock = {
        ...Structures["horizontalBlock"],
        ...{ id: hId, children: [vId] },
      };
      get().batchUpdate([node, vBlock, hBlock]);
      get().updateBlock(
        insertAt(hId, get().pages[get().currentPage].blocks, index)
      );
      get().setFocus(nodeId);
      get().setActiveNode(nodeId);
      get().setShowMenuButtons(popMenu);
    },

    createNode: (
      type = "paragraph",
      parentId,
      index = 0,
      data = {},
      popMenu = false
    ) => {
      const nodeId = "fl-" + uuidv4();
      let node = Structures[type];
      node = typeof node === "function" ? node() : node;
      node = { ...node, ...data, id: nodeId, parent: parentId, new: true };
      const parent = get().pages[get().currentPage].nodes[parentId];
      get().batchUpdate([
        node,
        { ...parent, children: insertAt(nodeId, parent.children, index) },
      ]);
      get().setFocus(nodeId);
      get().setActiveNode(nodeId);
      get().setShowMenuButtons(popMenu);
      return;
    },

    moveBlock: (id, index = 0) => {
      get().updateBlock([
        insertAt(id, get().pages[get().currentPage].blocks, index),
      ]);
    },

    moveNode: (id, newSiblingId, index = 0, parent = false) => {
      const newParentId = !parent
        ? get().pages[get().currentPage].nodes[newSiblingId].parent
        : newSiblingId;
      const newParent = get().pages[get().currentPage].nodes[newParentId];
      get().batchUpdate([
        { ...get().pages[get().currentPage].nodes[id], parent: newParentId },
        {
          ...newParent,
          children: insertAt(id, newParent.children, index),
        },
      ]);
    },

    copyNode: (id) => {
      const pages = get().pages[get().currentPage];
      const nodes = pages.nodes;
      let node = nodes[id];
      const nodeDOM = findNodeById(id);
      node = { ...node, text: nodeDOM.innerHTML };
      get().updateNode(id, node);

      // get().setUndo({
      //   node,
      //   focusedId: id,
      // });
      const newId = "fl-" + uuidv4();
      const nodeParent = nodes[node.parent];
      const nodeGParent = nodes[nodeParent.parent];
      if (
        nodeGParent.children.length === 1 &&
        nodeParent.type === "verticalBlock"
      ) {
        // Its a Block
        const blocks = pages.blocks;
        const index = blocks.indexOf(nodeGParent.id) + 1;

        get().updateNode(newId, { ...nodeGParent, id: newId });
        get().updateBlock(insertAt(newId, blocks, index));
      } else {
        // Its a node
        const index = nodeParent.children.indexOf(id) + 1;
        get().updateNode(newId, { ...node, id: newId });
        get().updateNode(node.parent, {
          ...nodeParent,
          children: insertAt(newId, nodeParent.children, index),
        });
      }
      const newNode = get().pages[get().currentPage].nodes[newId];
      get().duplicateNode(newNode, id);
    },

    duplicateNode: (node, ref) => {
      const page = get().pages[get().currentPage];
      const nodes = page.nodes;
      const children = [];
      if (node?.children?.length > 0) {
        for (let i = 0; i < node.children.length; i++) {
          const childId = node.children[i];
          const newId = "fl-" + uuidv4();
          const childNode = nodes[childId];
          children.push(newId);
          if (childNode?.children?.length > 0) {
            get().duplicateNode(
              { ...childNode, id: newId, parent: node.id },
              ref
            );
          } else {
            get().updateNode(newId, {
              ...childNode,
              id: newId,
              parent: node.id,
            });
            get().setActiveNode(newId);
            get().setFocus(newId);
          }
        }
      }
      get().updateNode(node.id, {
        ...node,
        children,
        new: null,
      });
    },

    replaceNode: (id, type, data = {}) =>
      set((state) => {
        let newNode = Structures[type];
        newNode = typeof newNode === "function" ? newNode() : newNode;
        const node = get().pages[get().currentPage].nodes[id];
        if (type === "page" && node.type !== "page") {
          const span = document.createElement("span");
          span.innerHTML = node.text;
          const text = span.textContent.trim();
          span.remove();
          data = {
            ...newNode,
            ...node,
            text,
            ...data,
            type,
            id,
          };
        } else if (type !== "page" && node.type === "page") {
          data = {
            ...newNode,
            ...node,
            type,
            ...data,
            id,
          };
        } else {
          data = {
            ...newNode,
            ...node,
            ...data,
            type,
          };
        }

        state.pages[get().currentPage].nodes[id] = { ...data, new: true };
        state.activeNode = id;
        state.showMenuButtons = false;
        state.showInlineTool = null;
      }),

    node2VBlock: (nodeId, siblingVBlock) => {
      const vId = "fl-" + uuidv4();
      const node = get().pages[get().currentPage].nodes[nodeId];
      const newHParentId =
        get().pages[get().currentPage].nodes[siblingVBlock].parent;
      const newParent = get().pages[get().currentPage].nodes[newHParentId];
      const index = newParent.children.indexOf(siblingVBlock) + 1;
      const vBlock = {
        ...Structures["verticalBlock"],
        id: vId,
        children: [nodeId],
        parent: newHParentId,
      };
      get().batchUpdate([
        { ...node, parent: vId, new: true },
        vBlock,
        {
          ...newParent,
          children: insertAt(vId, newParent.children, index),
        },
      ]);
      get().setActiveNode(nodeId);
      get().setFocus(nodeId);
      document.querySelector(".activeHBlock")?.classList.remove("activeHBlock");
      document
        .querySelector(".activeWrapper")
        ?.classList.remove("activeWrapper");
    },

    createNodeOrBlock: (id, data = {}, type = null) => {
      const nodes = get().pages[get().currentPage].nodes;
      const node = nodes[id];
      data = node?.element ? { ...data, element: node?.element } : data;
      if (!get().pages[get().currentPage].focusedNode) return;
      const nodeElement = findNodeById(id);
      type ??= "paragraph";

      const nodeParentId = node.parent;
      const nodeParent = nodes[nodeParentId];

      const vertical = nodeParent.type === "verticalBlock";
      const npParentId = nodeParent.parent;
      const npParent = nodes[npParentId];
      const onlyChild = npParent.children.length === 1; // Means the Node is the only child
      const blockNodes = ["callout", "todo", "toggle", "olist", "ulist"];

      if (
        ["todo", "olist", "ulist", "toggle", "callout"].includes(node.type) &&
        nodeElement.textContent === ""
      ) {
        // If its one of those above, convert to paragraph
        get().replaceNode(id, "paragraph");
      } else if (
        ["callout", "toggle"].includes(nodeParent.type) &&
        node.type === "paragraph" &&
        nodeElement.textContent === ""
      ) {
        if (
          nodeParent.children?.length - 1 ===
          nodeParent.children?.indexOf(id)
        ) {
          // If node Parent is one above and the current node is the last child and an empty paragraph, just create a new Paragraph after the parent and delete the node
          const index = npParent.children?.indexOf(nodeParentId) + 1;
          get().updateParent(id);
          get().deleteNode(id);
          get().createNode("paragraph", npParentId, index);
        }
      } else if (onlyChild && vertical) {
        // The node and its parent are alone. meaning its a block
        if (blockNodes.includes(node.type)) {
          if (["ulist", "olist"].includes(node.type)) {
            // For lists, create them in the current parent
            const index = nodeParent.children?.indexOf(node.id) + 1;
            get().createNode(node.type, nodeParentId, index, data);
          } else if (node.type === "callout") {
            get().createNode("paragraph", node.id, 0, data);
          } else {
            // Whatever, just create the next Block
            const index =
              get().pages[get().currentPage].blocks.indexOf(npParentId) + 1;
            get().createNewBlock(node.type, index, data);
          }
        } else {
          // Whatever, just create the next Block
          const index =
            get().pages[get().currentPage].blocks.indexOf(npParentId) + 1;
          get().createNewBlock(type, index, data);
        }
      } else {
        if (node.type === "callout") {
          get().createNode("paragraph", node.id, 0, data);
          return;
        }
        const index = nodeParent.children.indexOf(node.id) + 1;
        get().createNode(node.type, nodeParentId, index, data);
      }
    },

    handleMenuClick: (e = null, activeNode = null) => {
      e?.preventDefault();
      const page = get().pages[get().currentPage];
      const nodes = page.nodes;
      const blocks = page.blocks;
      activeNode = activeNode ?? page.activeNode;
      const node = nodes[activeNode];
      const nodeParentId = node.parent;
      const nodeParent = nodes[nodeParentId];
      const vertical = nodeParent.type === "verticalBlock";

      const npParentId = nodeParent.parent;
      const npParent = nodes[npParentId];
      const onlyChild = npParent.children.length === 1; // Means the Node is the only child

      if (onlyChild && vertical) {
        // The node and its parent are alone. meaning its a block
        const index = blocks.indexOf(npParentId) + 1;
        if (node.type === "paragraph" && node.text === "" && e) return;

        get().createNewBlock("paragraph", index); //type index data
        return;
      }
      const index = nodeParent.children.indexOf(activeNode) + 1;
      if (node.type === "paragraph" && node.text === "" && e) return;
      get().createNode("paragraph", nodeParentId, index);
    },

    keyPosition: null,
    setKeyPosition: (range) =>
      set((state) => {
        state.keyPosition = getAbsolutePosition(range);
      }),

    preventKey: null,
    setPreventkey: (status) =>
      set((state) => {
        state.preventKey = status;
      }),

    handleKeyUps: (e) => {
      const target = e.target;
      const id = target.closest(".nodeWrapper").getAttribute("data-id");
      const selection = window.getSelection();
      let nodeIndex = findNodeIndex(target, -1);
      nodeIndex = findNodeIndex(target, 1);
      if (get().preventKey) {
        get().setPreventkey(null);
        return;
      }

      switch (e.key) {
        case "@":
          const _range =
            selection?.rangeCount > 0 ? selection.getRangeAt(0) : null;
          if (!_range) return;
          get().fetchUsers();
          const cords = getAbsolutePosition(_range);
          get().setMentionCords(cords);
          get().setShowMention(true);
          get().setSearching({ startOffset: _range.startOffset });
          break;

        default:
          break;
      }
    },

    handleKeyDowns: (e) => {
      const target = e.target;
      const id = target.closest(".nodeWrapper")?.getAttribute("data-id");
      const node = get().pages[get().currentPage].nodes[id];
      // let cursor = get().pages[get().currentPage].undos.slice(-1)[0];
      // cursor = cursor.cursor;
      const text = target.textContent;
      const html = target.innerHTML;
      const editor = document.querySelector("#editor");
      const titleDom = document.querySelector(".title");
      const selection = window.getSelection();
      const selectedRange =
        selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
      if (!selectedRange) return;
      const range = document.createRange();
      range.selectNode(target.lastChild || target);
      const offset =
        selectedRange.endContainer === target.lastChild
          ? target.lastChild.textContent.length
          : target.textContent.length;

      const atStart =
        (selectedRange.startContainer === target ||
          selectedRange.startContainer === target.firstChild) &&
        selectedRange.endOffset === 0;

      let isEnd =
        selectedRange.endContainer === selectedRange.startContainer &&
        selectedRange.endOffset === offset;

      let nodeIndex = findNodeIndex(target, -1);
      const prevNode = nodeIndex < 0 ? titleDom : findElementByIndex(nodeIndex);
      nodeIndex = titleDom.isSameNode(target) ? 0 : findNodeIndex(target, 1);
      const nextNode = findElementByIndex(nodeIndex);

      let _selection = null;
      let _cursor = null;
      let default_cursor = ":0,:0";
      try {
        const selObj = rangy.getSelection();
        _selection = rangy.serializeSelection(selObj, true, editor);
        _cursor = rangy.serializeSelection(selObj, true, target);
      } catch (error) {}

      get().setKeyPosition(selectedRange);
      if (get().preventKey) {
        e.preventDefault();
        return;
      }
      const isRange = selection.type === "Range";
      switch (e.key) {
        case "/":
          if (text === "") {
            e.preventDefault();
            const clickEvent = new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            document.querySelector("#menuBtn")?.dispatchEvent(clickEvent);
            document
              .querySelector(".menuitems")
              .firstElementChild.classList.add("selected");
          }
          break;
        case "Enter":
          e.preventDefault();
          if (text === "") {
            get().createNodeOrBlock(id);
            return;
          }

          if (atStart) {
            target.innerHTML = "";
            get().updateNode(id, { text: "", cursor: default_cursor });
            get().createNodeOrBlock(id, {
              text: html,
              cursor: default_cursor,
            });
          } else if (isEnd) {
            get().updateNode(id, { text: html, cursor: _cursor });
            get().createNodeOrBlock(id, { cursor: default_cursor });
          } else {
            range.setStart(
              selectedRange.startContainer,
              selectedRange.startOffset
            );
            range.setEndAfter(target.lastChild);
            const content = range.extractContents();
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
            get().updateNode(id, { text: target.innerHTML });
            const span = document.createElement("span");
            span.append(content);
            get().createNodeOrBlock(id, {
              text: span.innerHTML,
              cursor: default_cursor,
            });
            span.remove();
          }
          break;
        case "ArrowLeft":
          if (atStart && prevNode) {
            e.preventDefault();
            selection.removeAllRanges();
            let lastChild = prevNode.lastChild;
            if (lastChild?.nodeName === "BR") {
              lastChild = lastChild?.previousSibling;
            }
            range.selectNodeContents(lastChild ?? prevNode);
            selection.addRange(range);
            selection.collapseToEnd();
            const nId = prevNode
              ?.closest(".nodeWrapper")
              ?.getAttribute("data-id");
            const selObj = rangy.getSelection();
            const _selection = rangy.serializeSelection(selObj, true, prevNode);
            get().updateNode(nId, { cursor: _selection });
            return;
          }
          break;
        case "ArrowRight":
          if (isEnd && nextNode) {
            e.preventDefault();
            range.setStart(nextNode, 0);
            range.setEnd(nextNode, 0);
            selection.removeAllRanges();
            selection.addRange(range);
          }
          break;
        case "Backspace":
          if (atStart || text === "") {
            e.preventDefault();
            if (node.type !== "paragraph") {
              get().replaceNode(id, "paragraph", { text: target.innerHTML });
            } else {
              if (prevNode && prevNode !== target) {
                prevNode.focus();
                range.selectNodeContents(prevNode);
                selection.addRange(range);
                selection.collapseToEnd();
                prevNode.insertAdjacentHTML("beforeend", html);
                get().updateParent(id);
                get().deleteNode(id);
                get().setShowMenuButtons(null);
              }
            }
          }
          break;

        case "Delete":
          if (isEnd && nextNode) {
            e.preventDefault();
            if (nextNode.textContent === "") {
              const nId = nextNode
                .closest(".nodeWrapper")
                .getAttribute("data-id");
              get().updateParent(nId);
              get().updateNode(nId);
            } else {
              e.preventDefault();
              const nId = nextNode
                .closest(".nodeWrapper")
                .getAttribute("data-id");
              range.selectNodeContents(target);
              selection.removeAllRanges();
              selection.addRange(range);
              selection.collapseToEnd();
              target.insertAdjacentHTML("beforeend", nextNode.innerHTML);
              get().updateParent(nId);
              get().deleteNode(nId);
            }
          }
          break;

        case "ArrowUp":
          isEnd =
            getAbsolutePosition(selectedRange).top -
              getAbsolutePosition(target).top <
            2.6;
          if (
            (isEnd || text === "") &&
            prevNode &&
            selection.type !== "Range"
          ) {
            let currentLeft = getAbsolutePosition(selectedRange).left;
            e.preventDefault();
            prevNode.focus();
            let lastChild;
            if (prevNode.lastChild?.nodeName === "BR") {
              lastChild = prevNode.lastChild?.previousSibling;
            } else {
              lastChild = prevNode.lastChild;
            }
            range.selectNodeContents(lastChild ?? prevNode);
            range.collapse(false);
            let i = range.endOffset;
            while (getAbsolutePosition(range).left > currentLeft && i > -1) {
              range.setStart(lastChild ?? prevNode, i);
              range.setEnd(lastChild ?? prevNode, i);
              i -= 1;
              if (getAbsolutePosition(range).left === currentLeft) break;
            }
            selection.removeAllRanges();
            selection.addRange(range);
          }

          break;
        case "ArrowDown":
          isEnd =
            getAbsolutePosition(target).bottom -
              getAbsolutePosition(selectedRange).bottom <
            2.6;
          if ((isEnd || text === "") && nextNode) {
            let currentLeft = getAbsolutePosition(selectedRange).left;
            e.preventDefault();
            nextNode.focus();
            let firstChild;
            if (nextNode.firstChild?.nodeName === "BR") {
              range.selectNodeContents(nextNode);
              range.collapse(true);
              selection.removeAllRanges();
              selection.addRange(range);
              return;
            } else {
              firstChild = nextNode.firstChild;
            }
            range.selectNodeContents(firstChild ?? nextNode);
            range.collapse(true);
            let i = range.endOffset;
            while (getAbsolutePosition(range).left < currentLeft) {
              range.setStart(firstChild ?? nextNode, i);
              range.setEnd(firstChild ?? nextNode, i);
              i += 1;
              if (
                getAbsolutePosition(range).left === currentLeft ||
                i > nextNode.textContent.length
              )
                break;
            }
            selection.removeAllRanges();
            selection.addRange(range);
          }
          break;
        case "Tab":
          e.preventDefault();
          const pId = prevNode.closest(".nodeWrapper").getAttribute("data-id");
          if (pId === get().pages[get().currentPage].nodes[id].parent) return;
          const pNode = get().pages[get().currentPage].nodes[pId];
          const index = pNode.children.length;
          const selObj = rangy.getSelection();
          const sel = rangy.serializeSelection(selObj, true, target);
          get().updateNode(id, { text: target.innerHTML, selection: sel });
          get().updateParent(id);
          get().moveNode(id, pId, index, true);
          break;
        default:
          break;
      }
    },

    handlePaste: (e) => {
      e.preventDefault();
      const target = e.target;
      const id = target.closest(".nodeWrapper").getAttribute("data-id");
      const node = get().pages[get().currentPage].nodes[id];
      let cursor = get().pages[get().currentPage].undos.slice(-1)[0];
      cursor = cursor.cursor;
      let _selection = null;
      let _cursor = null;
      let default_cursor = ":0,:0";
      const editor = document.querySelector("#editor");
      try {
        const selObj = rangy.getSelection();
        _selection = rangy.serializeSelection(selObj, true, editor);
        _cursor = rangy.serializeSelection(selObj, true, target);
      } catch (error) {}

      if (node.cursor !== default_cursor && cursor !== _selection) {
        get().setUndo({
          node: { ...node, text: "", cursor: default_cursor },
          focusedId: id,
        });
      }
      const targetId = get().pages[get().currentPage].activeNode;
      navigator.clipboard
        .readText()
        .then((clipText) => {
          const text = clipText.split("\n");
          for (let i = 0; i < text.length; i++) {
            const activeNode = get().pages[get().currentPage].activeNode;
            const txt = text[i].trim();
            // console.log(!!txt, txt.length, txt !== " ");

            !!txt &&
              txt !== "" &&
              txt.length > 1 &&
              get().createNodeOrBlock(activeNode, { text: txt });
          }
        })
        .finally(() => {
          if (target.textContent === "") {
            get().updateParent(targetId);
            get().deleteNode(targetId);
          }
        });
    },
    pasteData: async (e) => {
      // destinationDiv.innerText = ""; //Clear inner text
      try {
        const clipboardContents = await navigator.clipboard.read();
        for (const item of clipboardContents) {
          for (const mimeType of item.types) {
            const mimeTypeElement = document.createElement("p");
            mimeTypeElement.innerText = `MIME type: ${mimeType}`;
            // destinationDiv.appendChild(mimeTypeElement);
            if (mimeType === "image/png") {
              const pngImage = new Image(); // Image constructor
              pngImage.src = "image1.png";
              pngImage.alt = "PNG image from clipboard";
              const blob = await item.getType("image/png");
              pngImage.src = URL.createObjectURL(blob);
              // destinationDiv.appendChild(pngImage);
            } else if (mimeType === "text/html") {
              const blob = await item.getType("text/html");
              const blobText = await blob.text();
              const clipHTML = document.createElement("pre");
              clipHTML.innerHTML = blobText;
              console.log(blobText.trim());
              // destinationDiv.appendChild(clipHTML);
            } else if (mimeType === "text/plain") {
              const blob = await item.getType("text/plain");
              const blobText = await blob.text();
              const clipPlain = document.createElement("pre");
              console.log(blobText);
              clipPlain.innerText = blobText;
              // destinationDiv.appendChild(clipPlain);
            } else {
              throw new Error(`${mimeType} not supported.`);
            }
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    handleDrop: (e) => {
      // const newSiblingId = e.target.getAttribute('data-id')
      // get().moveNode(get().destinationNode, newSiblingId, 0, true)
    },

    setUndo: (data) =>
      set((state) => {
        console.log("Saving to Undos!!");
        const page = get().pages[get().currentPage];
        const blocks = page.blocks;
        const nodes = page.nodes;
        const db = {
          blocks,
          nodes: {
            ...nodes,
            [data.focusedId]: {
              ...nodes[data.focusedId],
              ...data.node,
              new: null,
              cursor: data.node?.cursor, //Incase its not given, make it null
            },
          },
          focusedId: data.focusedId,
          cursor: data.cursor, //Incase its not given, make it null
        };
        let undos = page.undos;
        let currentCursor = page.currentCursor;

        const lastUndo = undos[currentCursor];
        if (
          lastUndo &&
          JSON.stringify({
            nodes: lastUndo.nodes,
            blocks: lastUndo.blocks,
          }) === JSON.stringify({ nodes: db.nodes, blocks: db.blocks }) // If the previous db still remain as the last undo
        ) {
          return;
        }

        undos = undos.slice(0, Math.max(0, currentCursor + 1));
        undos.push(db);
        state.pages[get().currentPage].currentCursor = currentCursor + 1;
        state.pages[get().currentPage].undos = undos;
      }),

    doRedo: (pointer = null) =>
      set((state) => {
        const page = get().pages[get().currentPage];
        pointer = pointer ?? page.currentCursor;
        pointer += 1;
        // const dbBlocks = page.blocks;
        // const dbNodes = page.nodes;
        const lastUndo = page.undos[pointer];
        if (!lastUndo) return;
        console.log("Redo...", lastUndo, pointer);

        const { blocks, nodes, focusedId, cursor } = lastUndo;
        state.pages[get().currentPage] = {
          ...page,
          blocks,
          nodes,
          cursor,
          currentCursor: pointer,
          focusedNode: focusedId,
        };
      }),

    doUndo: (pointer = null) =>
      set((state) => {
        const currentPage = get().currentPage;
        const page = get().pages[currentPage];
        pointer = pointer ?? page.currentCursor;
        pointer = Math.max(0, pointer - 1);
        // const dbBlocks = page.blocks;
        // const dbNodes = page.nodes;
        const lastUndo = page.undos[pointer];
        if (!lastUndo) return;
        const { blocks, nodes, focusedId, cursor } = lastUndo;
        state.pages[currentPage] = {
          ...page,
          blocks,
          nodes,
          cursor,
          currentCursor: pointer,
          focusedNode: focusedId,
        };
      }),

    timer: setTimeout(() => {}, 0),
    save: (data, delay = 150) =>
      set((state) => {
        clearTimeout(get().timer);
        state.timer = setTimeout(() => get().setUndo(data), delay);
      }),

    delay: setTimeout(() => {}, 0),
    setTimer: (data, func, delay) =>
      set((state) => {
        clearTimeout(get().delay);
        state.delay = setTimeout(() => func(data), delay);
      }),

    users: null,
    setUsers: (users) =>
      set((state) => {
        state.users = users;
      }),
    fetchUsers: async () => {
      await fetch("https://jsonplaceholder.typicode.com/users")
        .then((resp) => {
          return resp.json();
        })
        .then((data) => get().setUsers(data))
        .catch((err) => console.log(err));
    },

    emojis: null,
    setEmojis: (emojis) =>
      set((state) => {
        state.emojis = emojis;
      }),
    fetchEmojis: async () => {
      await fetch(
        "https://emoji-api.com/emojis?access_key=38ac03b90cf8ab6a511977fff84bb6e9a72323ed"
      )
        .then((resp) => {
          return resp.json();
        })
        .then((data) => get().setEmojis(data))
        .catch((err) => console.log(err));
    },
    imageDialogIsOpen: null,
    setImageDialogIsOpen: (status) =>
      set((state) => {
        state.imageDialogIsOpen = status;
      }),
  }))
);
