import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { AnimatePresence, easeOut, motion } from "framer-motion";
import { MdPlayArrow } from "react-icons/md";
import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";

const Title = ({ node }) => {
  const { setDestination } = useDBStore((state) => state);
  const handleDragOver = (e) => {
    e.stopPropagation();
    const target = e.target.closest("[contenteditable]");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    target?.classList.remove("border-b");
    if (!target) return;
    node.collapsed && target?.classList.add("border-b");
    setDestination(node.id);
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();
    const target = document.querySelector(".dd.border-b");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    target?.classList.remove("border-b");
  };
  return (
    <ContentEditable
      id={node.id}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      className="dd outline-none text-normal px-1.5 pt-1.5 pb-1 min-w-full min-h-6 inline-block border-b-gray-400"
      aria-placeholder="Toggle"
    />
  );
};

const Toggle = ({ id, renderer }) => {
  const { pages, currentPage, createNode, updateNode } = useDBStore(
    (state) => state
  );
  const { nodes } = pages[currentPage];
  const node = nodes[id];
  return (
    <Disclosure key={id} as="div" className="w-full max-w-full">
      {({ open }) => (
        <>
          <div className="flex w-full">
            <DisclosureButton
              style={{ opacity: node.children.length > 0 ? 1 : 0.3 }}
              className={`${
                node.collapsed ? "rotate-90" : "rotate-0"
              }  size-[30px]`}
              onClick={() => updateNode(id, { collapsed: !open })}
            >
              <MdPlayArrow size="100%" />
            </DisclosureButton>
            <div className="w-full mx-1">
              <Title node={node} />
            </div>
          </div>
          <div className="overflow-hidden w-full ml-8">
            <AnimatePresence>
              {node.collapsed && (
                <DisclosurePanel
                  static
                  as={motion.div}
                  initial={{ opacity: 0, y: -24 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -24 }}
                  transition={{ duration: 0.2, ease: easeOut }}
                  className="origin-top"
                >
                  {node.children?.length === 0 && (
                    <span
                      className="text-gray-500 w-full items-center cursor-pointer flex py-1 px-3 rounded-sm"
                      onClick={() => createNode("paragraph", id, 0)}
                    >
                      Empty toggle. Click or drop blocks inside.
                    </span>
                  )}

                  {node.children.length > 0 &&
                    node.children?.map((childId) => {
                      return renderer(childId, nodes[childId].type);
                    })}
                </DisclosurePanel>
              )}
            </AnimatePresence>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default Toggle;
