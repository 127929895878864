import React from "react";
import { useDBStore } from "../stores/dbStore";
import { colors, Menus } from "../constants";

export const TurnIntoBlock = () => {
  const { currentPage, replaceNode, pages } = useDBStore((state) => state);
  const { nodes, activeNode } = pages[currentPage];
  const node = nodes[activeNode];
  const active = (type) => {
    return node?.type === type;
  };

  return (
    <div className="max-h-[350px] scroll-m-0.5 overflow-y-scroll h-auto bg-white border-b-gray-400/40 border left-0 w-[200px] m-auto rounded-lg animate__animated animate__bounceInUp">
      {Menus.filter(({ type }) => !["image"].includes(type)).map(
        ({ name, icon, type, element }, i) => {
          return (
            <div
              key={`${i}-${name}`}
              tabIndex={0}
              onClick={() =>
                !active(type) && replaceNode(activeNode, type, { element })
              }
              style={
                active(type)
                  ? { backgroundColor: "rgb(203 213 225 / 0.2)" }
                  : {}
              }
              className="group flex w-full h-[45px] my-2 px-2 focus:bg-slate-300/20 group-has-[.selected]:bg-slate-300/20 focus:outline-none hover:bg-slate-300/20 *:my-auto justify-between"
            >
              <span className="h-[30px] inline-flex  text-gray-500/80 w-[30px] p-2 justify-center items-center rounded-md border mr-2.5 *:size-[80px]">
                {icon}
              </span>
              <span className="mt-1 w-full text-gray-500/90 text-left">
                {name}
              </span>
            </div>
          );
        }
      )}
    </div>
  );
};

export const ColorBlock = () => {
  const { pages, currentPage, updateNode } = useDBStore((state) => state);
  const { activeNode, nodes } = pages[currentPage];
  const node = nodes[activeNode];

  const active = (type) => {
    const node = nodes[activeNode];
    return node?.type === type;
  };

  return (
    <div className="max-h-[400px] scroll-m-0.5 overflow-y-scroll h-auto bg-white border-b-gray-400/40 border left-0 w-[230px] m-auto rounded-lg animate__animated animate__bounceInUp">
      {colors.map(({ name, color, type }, i) => {
        return type === "delimiter" || type === "title" ? (
          <>
            {type === "delimiter" ? (
              <hr key={`${i}-${name}`} className="pointer-events-none mx-2" />
            ) : (
              <span
                key={`${i}-${name}`}
                className="not-selectable pointer-events-none m-2 w-full flex flex-col text-gray-500/80"
              >
                <small>{name}</small>
              </span>
            )}
          </>
        ) : (
          <div
            key={`${i}-${name}`}
            tabIndex={0}
            style={{
              backgroundColor:
                (color === node?.styles?.backgroundColor ||
                  color === node?.styles?.color) &&
                "rgb(203 213 225 / 0.2)",
            }}
            className="group flex w-full h-[45px] my-2 px-2 focus:bg-slate-300/20 group-has-[.selected]:bg-slate-300/20 focus:outline-none has-[.color]:hover:bg-slate-300/20 *:my-auto justify-between"
          >
            {(type === "color" || type === "background") && (
              <span
                onClick={() => {
                  const styles =
                    type === "background"
                      ? { ...node?.styles, backgroundColor: color }
                      : { ...node?.styles, color };
                  updateNode(activeNode, { styles });
                }}
              >
                <small
                  key="1"
                  style={
                    type === "background"
                      ? { backgroundColor: color, color: "black" }
                      : { color, backgroundColor: "white" }
                  }
                  className="color size-[30px] inline-flex text-white font-extrabold p-2 justify-center items-center rounded-md border mr-2.5 *:size-[80px]"
                >
                  A
                </small>
                <span
                  key="2"
                  className="mt-1 w-full text-gray-500/90 text-left"
                >
                  {name}
                </span>
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};
