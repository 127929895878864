import React from 'react'
// import { FaRocketchat } from "react-icons/fa";
import { GoClock } from "react-icons/go";
import { BsThreeDots, BsChatRightDots } from "react-icons/bs";

const RightSide = () => {
  return (
    <div className='flex w-[25%] align-middle *:px-1 *:my-[auto] *:font-semibold justify-evenly' >
         <a
          href="/dashboard"
          className="font-normal  text-slate-700 rounded-lg hover:bg-slate-100"
        >
          Home
        </a>
         <a
          href="/dashboard"
          className="font-normal  text-slate-700 rounded-lg hover:bg-slate-100"
        >
          <BsChatRightDots />
        </a>
        <a
          href="/dashboard"
          className="font-normal  text-slate-700 rounded-lg hover:bg-slate-100"
        >
          <GoClock />
        </a>
        <a
          href="/dashboard"
          className="font-normal  text-slate-700 rounded-lg hover:bg-slate-100"
        >
          <BsThreeDots />
        </a>
       
    </div>
  )
}

export default RightSide