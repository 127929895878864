import { MdOutlineFormatBold, MdOutlineFormatItalic } from "react-icons/md";
import { ImUnderline } from "react-icons/im";
import { FaChevronDown, FaCircle } from "react-icons/fa";
import { LiaLinkSolid, LiaUnlinkSolid } from "react-icons/lia";

import { useDBStore } from "../stores/dbStore";
import { PiTextAaBold } from "react-icons/pi";
import { FaChevronRight } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { Menus, colors } from "../constants";
import { findNodeById } from "../helpers";
import { rangy } from "../DOM";
import React from "react";
import { useLink } from "../hooks";

const Link = () => {
  const {
    inlineToolCords,
    showInlineTool,
    updateNode,
    replaceNode,
    currentPage,
    setShowInlineTool,
    pages,
  } = useDBStore((state) => state);
  const { navigateTo } = useLink();
  const { activeNode, nodes } = pages[currentPage];
  const node = nodes[activeNode];
  const page = pages[activeNode];
  const [url, setUrl] = useState("");

  const verifyUrl = (string) => {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    return urlRegex.test(string);
  };

  let allPages = Object.values(nodes).filter((v) => v.type === "page");
  const pagesArr = Object.values(pages);
  allPages = [...allPages, ...pagesArr].filter(
    ({ id, text, title }) => id && (text || title)
  );

  const handleClick = (id, text) => {
    const page = id ? pages[id] : null;
    const title = page?.title ?? text;
    let url = id ? `/${title}-${id}` : text;
    const tempSpan = document.querySelector("span.selected-portion");
    const link = document.createElement("a");
    link.href = url;
    link.title = text;
    link.target = id ? undefined : "_blank";
    link.contentEditable = false;
    link.onclick = (e) => {
      if (id) {
        e.preventDefault();
        navigateTo(id);
      }
    };
    link.innerHTML = tempSpan?.innerHTML;
    tempSpan.replaceWith(link);
  };

  return (
    <div
      className={`flex flex-col pt-9 relative max-w-full h-[fit-content] border bg-white`}
    >
      <input
        className={`rounded-sm px-2 py-1 m-auto w-full outline-none select-none`}
        type="text"
        autoFocus={true}
        placeholder="https://"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      {verifyUrl(url) && (
        <span
          key={"8765435"}
          className={`block w-full h-8 px-2 py-1 last:pb-2 m-auto border-b hover:bg-gray-300 bg-gray-200/50 truncate`}
          onClick={() => handleClick(null, url)}
        >
          {url}
        </span>
      )}
      {allPages?.map(({ id, text, title }) => {
        return (
          <span
            key={id}
            className="block w-full h-8 px-2 py-1 last:pb-2 m-auto border-b hover:bg-gray-300 bg-gray-200/50 truncate"
            onClick={() => handleClick(id, text ?? title)}
          >
            {title ?? text}
          </span>
        );
      })}
    </div>
  );
};

const InlineToolbar = () => {
  const {
    inlineToolCords,
    showInlineTool,
    updateNode,
    replaceNode,
    currentPage,
    setPreventkey,
    setShowInlineTool,
    pages,
  } = useDBStore((state) => state);
  const { activeNode, nodes } = pages[currentPage];
  const node = nodes[activeNode];
  const $this = useRef(null);
  const rangeRef = useRef(null);
  const textRef = useRef(null);
  const [open, setOpen] = useState(null);
  const [url, setUrl] = useState("");
  const editor = document.querySelector("#editor");

  const handleLink = (e) => {
    const selection = window.getSelection();
    const selectedRange =
      selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    console.log(selectedRange.toString());
  };

  useEffect(() => {
    if (showInlineTool && $this.current) {
      const selection = window.getSelection();
      const range = selection?.rangeCount > 0 ? selection?.getRangeAt(0) : null;
      if (!range) return;
      const focusNode = range.startContainer.parentNode;
      const anchorNode = range.endContainer.parentNode;
      const boldBtn = $this.current.querySelector(".btn-bold");
      const italicBtn = $this.current.querySelector(".btn-italic");
      const underlineBtn = $this.current.querySelector(".btn-underline");
      const linkBtn = $this.current.querySelector(".btn-link");
      const inBold = anchorNode?.closest("b") && focusNode?.closest("b");
      const inItalic = anchorNode?.closest("i") && focusNode?.closest("i");
      const inUnderline = anchorNode?.closest("u") && focusNode?.closest("u");
      const inLink = anchorNode?.closest("a") && focusNode?.closest("a");
      inLink && setUrl(anchorNode?.closest("a")?.href ?? "");

      inBold
        ? boldBtn?.classList.add("active")
        : boldBtn?.classList.remove("active");
      inItalic
        ? italicBtn?.classList.add("active")
        : italicBtn?.classList.remove("active");
      inUnderline
        ? underlineBtn?.classList.add("active")
        : underlineBtn?.classList.remove("active");
      inLink
        ? linkBtn?.classList.add("active")
        : linkBtn?.classList.remove("active");
    }
  });

  return (
    <div
      ref={$this}
      style={{
        display: showInlineTool ? "block" : "none",
        top: inlineToolCords.bottom + 20,
        left: inlineToolCords.left,
      }}
      onMouseLeave={() => setOpen(null)}
      className="inline-tool absolute z-20 w-[fit-content] flex flex-col *:select-none rounded-md"
    >
      <div className="bg-white inline-tool border absolute z-20 w-[fit-content] flex flex-col *:select-none rounded-md">
        <div className="flex not-selectable justify-center gap-1 items-center px-1 *:size-[30px] *:rounded-sm  *:mx-0.5 *:my-auto *:flex *:justify-center *:items-center *:font-bold">
          <button
            className="group flex hover:bg-slate-300"
            onMouseOver={() => setOpen("turn-to")}
            onMouseOut={() => setOpen(null)}
          >
            <PiTextAaBold size={24} />
            <FaChevronRight
              style={{ display: open === "turn-to" ? "none" : "inline-block" }}
              size={10}
              className="block group-focus:hidden ml-0.5"
            />
            <FaChevronDown
              style={{ display: open === "turn-to" ? "inline-block" : null }}
              size={10}
              className="group-focus:block ml-0.5"
            />
          </button>
          <button
            onMouseOver={() => setOpen(null)}
            onClick={() => document.execCommand("bold", false)}
            className="btn-bold hover:bg-slate-300"
          >
            <MdOutlineFormatBold size={24} />
          </button>
          <button
            onMouseOver={() => setOpen(null)}
            onClick={() => document.execCommand("italic", false)}
            className="btn-italic hover:bg-slate-300"
          >
            <MdOutlineFormatItalic size={24} />
          </button>
          <button
            onMouseOver={() => setOpen(null)}
            onClick={() => document.execCommand("underline", false)}
            className="btn-underline *:mb-0.5 hover:bg-slate-300"
          >
            <ImUnderline size={18} />
          </button>
          <button
            className="btn-link hover:bg-slate-300"
            onClick={(e) => {
              e.preventDefault();
              if (open === "link") {
                const span = document.querySelector(".selected-portion");
                span.replaceWith(span.innerHTML);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(rangeRef.current);
                rangeRef.current = null;
                setOpen(null);
                setShowInlineTool(true);
                return;
              }
              const selection = window.getSelection();
              const selectedRange = selection.getRangeAt(0);
              rangeRef.current = selectedRange.cloneRange();
              const span = document.createElement("span");
              span.classList.add("selected-portion");
              selectedRange.surroundContents(span);
              setOpen("link");
            }}
          >
            <LiaLinkSolid size={22} />
          </button>
          {/* <button
          onMouseOver={() => setOpen(null)}
          className="btn-color *:mb-0.5 hover:bg-slate-300"
        >
          <FaCircle size={18} />
        </button> */}
          <button
            onMouseOver={() => setOpen("color")}
            className="*:mb-0.5 hover:bg-slate-300"
          >
            <FaCircle fill="red" size={18} />
          </button>
        </div>
      </div>
      <div className="w-auto h-auto text-gray-500">
        <div
          onMouseOver={() => setOpen("turn-to")}
          className={`${
            open === "turn-to" ? "flex" : "hidden"
          } border w-[150px] bg-white py-2 -mt-0.5 h-[fit-content] flex-col`}
        >
          {Menus.filter(({ type }) => !["image"].includes(type)).map(
            ({ name, icon, type, element }, i) => {
              return (
                <span
                  key={`${i}-${name}`}
                  onClick={() => replaceNode(activeNode, type, { element })}
                  className="flex justify-start hover:bg-gray-200/90 px-2 py-1"
                >
                  <span>{icon}</span> <small className="ml-4">{name}</small>
                </span>
              );
            }
          )}
        </div>
        {open === "link" && <Link />}

        <div
          // onMouseOver={() => setOpen("color")}
          onClick={() => (open === "color" ? setOpen(null) : setOpen("color"))}
          style={{ display: open === "color" ? "flex" : "none" }}
          className="border relative left-1/2 w-[155px] bg-white py-2 h-[fit-content] flex-col"
        >
          {colors.map(({ name, color, complement, type }, i) => {
            return type === "title" || type === "delimiter" ? (
              <>
                {type === "delimiter" ? (
                  <hr
                    key={`${i}-${name}`}
                    className="pointer-events-none mx-2"
                  />
                ) : (
                  <small
                    key={`${i}-${name}`}
                    className="mx-2 w-full pointer-events-none"
                  >
                    {name}
                  </small>
                )}
              </>
            ) : (
              <span
                key={`${i}-${name}`}
                className="flex justify-start items-center hover:bg-gray-200/90 px-2 py-1"
                onClick={() => {
                  color === "default"
                    ? document.execCommand("removeFormat", false, null)
                    : document.execCommand("hiliteColor", false, color);
                }}
              >
                <span
                  style={
                    type === "background"
                      ? { backgroundColor: color, color: "black" }
                      : { color }
                  }
                  className="rounded-full h-6 pb-2.5 pl-1.5 w-7"
                >
                  A
                </span>
                <small className="ml-4">{name}</small>
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

/*

<span
    key={`${i}-${name}`}
    className="flex justify-start hover:bg-gray-200/90 px-2 py-1"
  >
    <span
      style={
        type === "background"
          ? { backgroundColor: color, color: "black" }
          : { color }
      }
      className="rounded-full h-6 pb-2.5 pl-1.5 w-7"
      onClick={() => {
        const styles =
          type === "background"
            ? { ...node?.styles, backgroundColor: color }
            : { ...node?.styles, color };
        updateNode(activeNode, { styles });
      }}
    >
      A
    </span>
    <small
      className="ml-4"
      onClick={() => {
        const styles =
          type === "background"
            ? { ...node?.styles, backgroundColor: color }
            : { ...node?.styles, color };
        updateNode(activeNode, { styles });
      }}
    >
      {name}
    </small>
  </span>

*/
export default InlineToolbar;
