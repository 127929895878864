import Dropzone from "dropzone";
import sanitizeHtml from "sanitize-html";

export const getAbsolutePosition = (element) => {
  const rects = element.getBoundingClientRect();
  const x = window.scrollX || document.documentElement.scrollLeft;
  const y = window.scrollY || document.documentElement.scrollTop;
  const top = rects.top + y; // + window.screenY;
  const bottom = rects.bottom + y; // + window.screenY;
  const left = rects.left + x; // + window.screenX;

  return { top, bottom, left, height: rects.height, width: rects.width };
};

export const insertAt = (target, col = [], index = 0) => {
  return [...col.slice(0, index), target, ...col.slice(index)];
};

export const findNodeIndex = (
  target,
  dir = 0,
  selector = "[contenteditable]"
) => {
  const allMatch = document.querySelectorAll(".nodeWrapper " + selector);
  let index = 0;
  for (let i = 0; i < allMatch.length; i++) {
    if (allMatch[i] === target) {
      index = i + dir;
      break;
    }
  }

  return index;
};

export const findElementByIndex = (index, selector = "[contenteditable]") => {
  const allMatch = document.querySelectorAll(".nodeWrapper " + selector);
  return allMatch[index];
};

export const findNodeById = (nodeId, parentNode = null) => {
  return parentNode
    ? parentNode.querySelector(
        ".nodeWrapper[data-id=" + nodeId + "] [contenteditable]"
      )
    : document.querySelector(
        ".nodeWrapper[data-id=" + nodeId + "] [contenteditable]"
      );
};

const sanitizeConfig = {
  allowedTags: ["strong", "u", "em", "span"],
  allowedAttributes: { a: ["href"] },
};

export const sanitize = (html, config = sanitizeConfig) => {
  return sanitizeHtml(html, config);
};

var dropZoneInstance = null;
export const attachDropzone = (element, config = {}) => {
  if (dropZoneInstance) {
    dropZoneInstance.destroy();
  }

  dropZoneInstance = new Dropzone(element, { url: "uploads/", ...config });
};

export const forwardSelection = (startElement, anchorNode, offset) => {
  //this checks if the anchor node of a selection is within the startElement in a NodeList
  const range = document.createRange();
  range.selectNode(startElement);
  return range.isPointInRange(anchorNode, offset);
};

export const completelyInRange = (range, element) => {
  const eleRange = document.createRange();
  eleRange.selectNode(element);
  const leftIsBefore =
    range.compareBoundaryPoints(Range.START_TO_START, eleRange) <= 0;
  const rightIsAfter =
    range.compareBoundaryPoints(Range.END_TO_END, eleRange) >= 0;
  return leftIsBefore && rightIsAfter;
};
