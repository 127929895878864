const H3 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-3{isolation:isolate}.cls-4{fill:#3c3b3b}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_13" data-name="Layer 13">
        <g id="Group_9079" data-name="Group 9079">
          <g id="Rectangle_40-2" data-name="Rectangle 40-2">
            <rect
              width={60}
              height={60}
              rx={4}
              style={{
                fill: "none",
              }}
            />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              style={{
                fill: "#9f9f9f",
              }}
            />
          </g>
          <g id="H3" className="cls-3">
            <g className="cls-3">
              <path
                d="M30.44 41h-5.11v-8.91h-7.58V41h-5.14V19.67h5.14v8.47h7.58v-8.47h5.11Z"
                className="cls-4"
              />
            </g>
            <g className="cls-3">
              <path
                d="M37.16 32.51h1.6a1.77 1.77 0 0 0 1.36-.51 1.89 1.89 0 0 0 .43-1.32 1.74 1.74 0 0 0-.1-.61 1.43 1.43 0 0 0-.83-.86 2.08 2.08 0 0 0-.74-.13 2 2 0 0 0-.6.1 1.75 1.75 0 0 0-.51.26 1.24 1.24 0 0 0-.37.42 1 1 0 0 0-.15.55H34a3.35 3.35 0 0 1 .39-1.62 3.76 3.76 0 0 1 1.06-1.22 5 5 0 0 1 1.53-.76 6 6 0 0 1 1.82-.26 7.52 7.52 0 0 1 2.07.26 4.6 4.6 0 0 1 1.6.78 3.37 3.37 0 0 1 1 1.27 3.86 3.86 0 0 1 .38 1.75 3 3 0 0 1-.16.94 3.07 3.07 0 0 1-.44.84 3.89 3.89 0 0 1-.69.72 5.54 5.54 0 0 1-.91.56 3.8 3.8 0 0 1 1.78 1.24 3.3 3.3 0 0 1 .64 2.09 3.78 3.78 0 0 1-.4 1.77 4 4 0 0 1-1.12 1.32 5.22 5.22 0 0 1-1.69.82 7.84 7.84 0 0 1-2.1.28 7 7 0 0 1-1.2-.11 6.64 6.64 0 0 1-1.16-.32 5.22 5.22 0 0 1-1-.56 3.59 3.59 0 0 1-.85-.8 4.05 4.05 0 0 1-.56-1 3.89 3.89 0 0 1-.25-1.4h3.31a1.4 1.4 0 0 0 .15.64 1.48 1.48 0 0 0 .39.51 1.77 1.77 0 0 0 .58.34 2.08 2.08 0 0 0 .71.13 2.28 2.28 0 0 0 .8-.14 1.63 1.63 0 0 0 .6-.36 1.57 1.57 0 0 0 .37-.55 1.67 1.67 0 0 0 .13-.67 1.89 1.89 0 0 0-.54-1.53 2.24 2.24 0 0 0-1.48-.37h-1.6Z"
                className="cls-4"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default H3;
