import React, { useEffect, useRef, useState } from "react";
import { useDBStore } from "../../stores/dbStore";
import { Container } from '@column-resizer/react';

const HorizontalBlock = ({ id, renderer }) => {
  const { pages, currentPage, updateNode, setShowMenuButtons, setDestination } = useDBStore((state) => state);
  const [key, setKey] = useState(null)
  const $this = useRef(null);
  const { nodes, focusedNode } = pages[currentPage];
  const node = nodes[id];

  const handleDragOver = (e) => {
    const hBlock = e.target.previousElementSibling;
    const y = e.clientY;
    const rects = e.target.getBoundingClientRect();
    const id = hBlock.getAttribute("data-id");
    if (Math.abs(rects.bottom - y) < 40) {
      document.querySelector(".activeBlock")?.classList.remove("activeBlock");
      document
        .querySelector(".activeWrapper")
        ?.classList.remove("activeWrapper");
      e.target.classList.add("activeBlock");
      setDestination(id);
      return;
    }
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
  };

  const handleDragLeave = () => {
    const target = document.querySelector(".activeWrapper");
    target?.classList.remove("activeWrapper");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    // setDestination(null);
  };





  const handleAfterResize = () => {
    const size = $this.current.getAttribute('data-resize-size');
    const resizingId = $this.current.getAttribute('data-resizing-id');
    $this.current.removeAttribute('data-resize-size')
    $this.current.removeAttribute('data-resizing-id')
    updateNode(resizingId, { style: { width: parseInt(size) } });
  }

  useEffect(() => {
    setKey(Math.floor(Math.random() * 1000)) // this rerenders the Contaner after dropping a new column
  }, [node.children])


  return (
    <>
      <Container
        key={key}
        data-id={id}
        ref={$this}
        className="h-block flex flex-row w-full min-h-4"
        minSize={100}
        vertical={false}
        afterResizing={handleAfterResize}
        beforeApplyResizer={() => setShowMenuButtons(null)}
      >
        {node.children?.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
      </Container>
      <div
        data-id={id}
        className="h-2"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}></div>
    </>
  );
};

export default HorizontalBlock;
