const NumberedList = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>
        {".cls-2{fill:#9f9f9f}.cls-3{isolation:isolate}.cls-4{fill:#3c3b3b}"}
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Heart">
        <g id="Group_8999" data-name="Group 8999">
          <g id="Rectangle_40-11" data-name="Rectangle 40-11">
            <rect
              width={60}
              height={60}
              rx={4}
              style={{
                fill: "none",
              }}
            />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              className="cls-2"
            />
          </g>
          <g id="Group_8998" data-name="Group 8998">
            <path
              id="Line_846-2"
              d="M53 26H29a1 1 0 0 1 0-2h24a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 846-2"
            />
            <path
              id="Line_847-2"
              d="M44 32H29a1 1 0 0 1 0-2h15a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 847-2"
            />
            <path
              id="Line_855"
              d="M47 38H29a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 855"
            />
            <g id="Group_8997" data-name="Group 8997">
              <g id="_1" className="cls-3" data-name={1}>
                <g className="cls-3">
                  <path
                    d="M17 40h-4V24.77l-4.72 1.46V23l8.25-2.95H17Z"
                    className="cls-4"
                  />
                </g>
              </g>
              <circle
                id="Ellipse_979"
                cx={22.5}
                cy={38.5}
                r={1.5}
                className="cls-4"
                data-name="Ellipse 979"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default NumberedList;
