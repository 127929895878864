const Divider = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-2{fill:#9f9f9f}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Heart">
        <g id="Group_9013" data-name="Group 9013">
          <g id="Rectangle_40" data-name="Rectangle 40">
            <rect
              width={60}
              height={60}
              rx={4}
              style={{
                fill: "none",
              }}
            />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              className="cls-2"
            />
          </g>
          <g id="Group_9012" data-name="Group 9012">
            <g id="Group_9011" data-name="Group 9011">
              <path
                id="Line_846"
                d="M43 13H11a1 1 0 0 1 0-2h32a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 846"
              />
              <path
                id="Line_856"
                d="M37 18H11a1 1 0 0 1 0-2h26a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 856"
              />
              <path
                id="Line_857"
                d="M40 23H11a1 1 0 0 1 0-2h29a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 857"
              />
            </g>
            <g id="Group_9010" data-name="Group 9010">
              <path
                id="Line_860"
                d="M44 39H11a1 1 0 0 1 0-2h33a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 860"
              />
              <path
                id="Line_859"
                d="M44 44H11a1 1 0 0 1 0-2h33a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 859"
              />
              <path
                id="Line_858"
                d="M35 49H11a1 1 0 0 1 0-2h24a1 1 0 0 1 0 2Z"
                className="cls-2"
                data-name="Line 858"
              />
            </g>
            <path
              id="Line_847"
              d="M49.5 31h-38a1 1 0 0 1 0-2h38a1 1 0 0 1 0 2Z"
              data-name="Line 847"
              style={{
                fill: "#3c3b3b",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Divider;
