import React, { useEffect, useRef, useState } from "react";
import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";

const Paragraph = ({ id, renderer }) => {
  const { pages, currentPage } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];
  return (
    <>
      <ContentEditable
        id={id}
        className="paragraph outline-none text-normal px-1.5 my-0.5 min-w-full min-h-5 inline-block"
        aria-placeholder="Write or press ‘/’ for commands…"
      />
      <div className="pl-4">
      {node.children.map((childId) => {
        return renderer(childId, nodes[childId].type);
      })}
      </div>
    </>
  );
};

export default Paragraph;
