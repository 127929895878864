import Paragraph from "./components/plugins/Paragraph";
import Callout from "./components/plugins/Callout";
import Toggle from "./components/plugins/Toggle";
import Todo from "./components/plugins/Todo";
import Heading from "./components/plugins/Heading";
import Divider from "./components/plugins/Divider";
import { OList, UList } from "./components/plugins/List";
import HorizontalBlock from "./components/plugins/HorizontalBlock";
import VerticalBlock from "./components/plugins/VerticalBlock";
import Wrapper from "./components/content/Wrapper";
import Quote from "./components/plugins/Quote";
import Page from "./components/plugins/Page";
import { MdDeleteForever } from "react-icons/md";
import { ImMoveUp, ImMoveDown } from "react-icons/im";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { LuRepeat2 } from "react-icons/lu";
import { IoIosColorPalette } from "react-icons/io";
import { ColorBlock, TurnIntoBlock } from "./components/ExtraMenus";
import Image from "./components/plugins/Image";
import BulletListIcon from "./icons/BulletedList";
import TextIcon from "./icons/Text";
import H1 from "./icons/H1";
import H2 from "./icons/H2";
import H3 from "./icons/H3";
import CalloutIcon from "./icons/Callout";
import ToggleIcon from "./icons/Toggle";
import QuoteIcon from "./icons/Quote";
import TodoIcon from "./icons/Todo";
import DividerIcon from "./icons/Divider";
import PageIcon from "./icons/Page";
import ImageIcon from "./icons/Image";
import NumberedListIcon from "./icons/NumberedList";
import BulletedListIcon from "./icons/BulletedList";

export const Menus = [
  {
    name: "Text",
    type: "paragraph",
    icon: <TextIcon />,
    description: "Write in plain text",
    shortcut: "Alt + T",
  },
  {
    name: "Heading 1",
    type: "heading",
    icon: <H1 />,
    element: "h1",
    description: "Big size heading",
    shortcut: "Alt + 1",
  },
  {
    name: "Heading 2",
    type: "heading",
    icon: <H2 />,
    element: "h2",
    description: "Medium size heading",
    shortcut: "Alt + 2",
  },
  {
    name: "Heading 3",
    type: "heading",
    icon: <H3 />,
    element: "h3",
    description: "Small size heading",
    shortcut: "Alt  + H",
  },
  {
    name: "CallOut",
    type: "callout",
    icon: <CalloutIcon />,
    description: "Highlight important contents",
    shortcut: "Alt + C",
  },
  {
    name: "Page",
    type: "page",
    icon: <PageIcon />,
    description: "Create a page inside the page",
    shortcut: "Alt + N",
  },
  {
    name: "Quote",
    type: "quote",
    icon: <QuoteIcon />,
    description: "Quote a content",
    shortcut: "Alt + Q",
  },
  {
    name: "Image",
    type: "image",
    icon: <ImageIcon />,
    description: "Upload an Image",
    shortcut: "Alt + E",
  },
  {
    name: "Numbered List",
    type: "olist",
    icon: <NumberedListIcon />,
    description: "Create a numbered list",
    shortcut: "Alt + I",
  },
  {
    name: "Bulleted List",
    type: "ulist",
    icon: <BulletedListIcon />,
    description: "Create a bulleted list",
    shortcut: "Alt + .",
  },
  {
    name: "To-do list",
    type: "todo",
    icon: <TodoIcon />,
    description: "Create a to-do list",
    shortcut: "Alt + D",
  },
  {
    name: "Toggle List",
    type: "toggle",
    icon: <ToggleIcon />,
    description: "Hide or show contents",
    shortcut: "Alt + G",
  },
  {
    name: "Line Break",
    type: "divider",
    icon: <DividerIcon />,
    description: "divide the contents segment",
    shortcut: "Alt + V",
  },
];

export const SubMenus = [
  {
    name: "Turn into",
    cmd: () => {},
    icon: <LuRepeat2 size={80} />,
    menu: <TurnIntoBlock />,
    shortcut: "⌘ + ⌥ + D",
  },
  {
    name: "Duplicate",
    cmd: () => {},
    icon: <HiOutlineDocumentDuplicate size={80} />,
    shortcut: "⌘ + ⌥ + D",
  },
  { separator: true },
  {
    name: "Move Up",
    cmd: () => {},
    icon: <ImMoveUp size={80} />,
    shortcut: "⌘ + ⌥ + D",
  },
  {
    name: "Delete",
    cmd: () => {},
    icon: <MdDeleteForever size={80} />,
    shortcut: "⌘ + ⌥ + D",
  },
  {
    name: "Move Down",
    cmd: () => {},
    icon: <ImMoveDown size={80} />,
    shortcut: "⌘ + ⌥ + D",
  },
  { separator: true },
  {
    name: "Color",
    cmd: () => {},
    icon: <IoIosColorPalette size={80} />,
    menu: <ColorBlock />,
    shortcut: "⌘ + ⌥ + D",
  },
];

export const Structures = {
  paragraph: {
    type: "paragraph",
    text: "",
    children: [],
  },
  callout: {
    type: "callout",
    text: "",
    icon: "",
    background: "",
    children: [],
  },
  page: {
    type: "page",
    text: null,
    icon: "",
    link: "",
  },
  toggle: {
    type: "toggle",
    collapsed: false,
    text: "",
    children: [],
  },
  todo: {
    type: "todo",
    text: "",
    done: false,
    children: [],
  },
  image: {
    type: "image",
    text: "",
  },
  quote: {
    type: "quote",
    text: "",
    children: [],
  },
  heading: (element) => ({
    type: "heading",
    text: "",
    element,
  }),
  divider: {
    type: "divider",
  },
  ulist: {
    type: "ulist",
    text: "",
    children: [],
  },
  olist: {
    type: "olist",
    text: "",
    children: [],
  },
  horizontalBlock: {
    type: "horizontalBlock",
    children: [],
  },
  verticalBlock: {
    type: "verticalBlock",
    children: [],
  },
};

export const Elements = {
  paragraph: (id, fn) => (
    <Wrapper id={id}>
      <Paragraph id={id} renderer={fn} />
    </Wrapper>
  ),
  callout: (id, fn) => (
    <Wrapper id={id}>
      <Callout id={id} renderer={fn} />
    </Wrapper>
  ),
  page: (id, fn) => (
    <Wrapper id={id}>
      <Page id={id} renderer={fn} />
    </Wrapper>
  ),
  toggle: (id, fn) => (
    <Wrapper id={id}>
      <Toggle id={id} renderer={fn} />
    </Wrapper>
  ),
  image: (id) => (
    <Wrapper id={id}>
      <Image id={id} />
    </Wrapper>
  ),
  todo: (id, fn) => (
    <Wrapper id={id}>
      <Todo id={id} renderer={fn} />
    </Wrapper>
  ),
  quote: (id, fn) => (
    <Wrapper id={id}>
      <Quote id={id} renderer={fn} />
    </Wrapper>
  ),
  heading: (id, fn) => (
    <Wrapper id={id}>
      <Heading id={id} renderer={fn} />
    </Wrapper>
  ),
  divider: (id, fn) => (
    <Wrapper id={id}>
      <Divider id={id} />
    </Wrapper>
  ),
  ulist: (id, fn) => (
    <Wrapper id={id}>
      <UList id={id} renderer={fn} />
    </Wrapper>
  ),
  olist: (id, fn) => (
    <Wrapper id={id}>
      <OList id={id} renderer={fn} />
    </Wrapper>
  ),
  horizontalBlock: (id, fn) => <HorizontalBlock id={id} renderer={fn} />,
  verticalBlock: (id, fn) => <VerticalBlock id={id} renderer={fn} />,
};

export const colors = [
  // { name: "Colors", type: "title" },
  // { type: "delimiter" },
  // { name: "Default", type: "color", color: "gray" },
  // { name: "Brown", type: "color", color: "brown" },
  // { name: "Orange", type: "color", color: "orange" },
  // { name: "Yellow", type: "color", color: "yellow" },
  // { name: "Green", type: "color", color: "green" },
  // { name: "Blue", type: "color", color: "blue" },
  { name: "Backgrounds", type: "title" },
  { type: "delimiter" },
  { name: "Default Background", type: "background", color: "default" },
  {
    name: "Gray Background",
    type: "background",
    color: "gray",
    complement: "gray",
  },
  {
    name: "Brown Background",
    type: "background",
    color: "brown",
    complement: "teal",
  },
  {
    name: "Orange Background",
    type: "background",
    color: "orange",
    complement: "blue",
  },
  {
    name: "Yellow Background",
    type: "background",
    color: "yellow",
    complement: "purple",
  },
  {
    name: "Green Background",
    type: "background",
    color: "green",
    complement: "red",
  },
  {
    name: "Blue Background",
    type: "background",
    color: "blue",
    complement: "orange",
  },
];
