const Image = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <g data-name="Layer 2">
      <g data-name="Group 9032">
        <g data-name="Rectangle 40-6">
          <rect
            width={60}
            height={60}
            rx={4}
            style={{
              fill: "none",
            }}
          />
          <path
            d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
            style={{
              fill: "#9f9f9f",
            }}
          />
        </g>
        <path
          d="M42.2 13H17.79A4.8 4.8 0 0 0 13 17.8v24.41A4.8 4.8 0 0 0 17.79 47H42.2a4.8 4.8 0 0 0 4.8-4.79V17.8a4.8 4.8 0 0 0-4.8-4.8Zm2.18 29.2a2.17 2.17 0 0 1-2.18 2.18H17.79a2.17 2.17 0 0 1-2.17-2.18V39l6.89-5 4.32 4.94a1.1 1.1 0 0 0 .28.2l.15.1a1.28 1.28 0 0 0 1 0l.14-.09a1.1 1.1 0 0 0 .26-.15l10-9.31 5.7 6.31Zm0-10.1-4.65-5.17a.75.75 0 0 0-.2-.15 1.13 1.13 0 0 0-.21-.15 1.05 1.05 0 0 0-.24-.06.64.64 0 0 0-.49 0 1 1 0 0 0-.25 0l-.44.26-10 9.3-4.22-4.82a.83.83 0 0 0-.16-.12 1.46 1.46 0 0 0-.21-.16 1 1 0 0 0-.24-.09.84.84 0 0 0-.24-.06h-.23a.8.8 0 0 0-.48.14l-.19.08-6.31 4.6V17.79a2.17 2.17 0 0 1 2.17-2.17H42.2a2.17 2.17 0 0 1 2.18 2.17Zm-18.7-9.77a2.62 2.62 0 1 1-2.61-2.61 2.61 2.61 0 0 1 2.61 2.61Z"
          data-name="Path 8728"
          style={{
            fill: "#3c3b3b",
          }}
        />
      </g>
    </g>
  </svg>
);
export default Image;
