import React, { memo, useCallback, useEffect, useRef } from "react";
import { useDBStore } from "../../stores/dbStore";
import { getAbsolutePosition } from "../../helpers";
import { rangy } from "../../DOM";

const Wrapper = memo(({ id, children }) => {
  const {
    setHover,
    setFocusCords,
    setActiveNode,
    showMenu,
    setShowMenuButtons,
    pages,
    setUndo,
    currentPage,
    draggedNode,
    showInlineTool,
    setDestination,
    updateNode,
  } = useDBStore((state) => state);
  const { nodes, focusedNode } = pages[currentPage];
  const ele = useRef("");
  const node = nodes[id];

  const handleMouseOver = (e) => {
    e.stopPropagation();
    if (!showMenu && !showInlineTool) {
      const target = e.target.closest(".nodeWrapper[data-id]");
      const targetId = target?.getAttribute("data-id");
      setHover(targetId);
      setActiveNode(targetId);
      const cords = getAbsolutePosition(
        node.type === "image" ? target.querySelector(".edge") ?? target : target
      );
      setFocusCords(cords);
      setShowMenuButtons(false);
      setTimeout(() => {
        setShowMenuButtons(true); // For Animation purposes only
      }, 100);
    }
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    const wrapper = e.target.closest(".nodeWrapper");
    const draggedWrapper = document.querySelector(
      ".nodeWrapper[data-id='" + draggedNode + "']"
    );
    const _id = wrapper?.getAttribute("data-id");
    const vBlockId = wrapper?.closest(".v-block").getAttribute("data-id");
    const nodeHParentId = nodes[vBlockId].parent;
    const nodeParentId = nodes[_id].parent;
    const nodeParent = nodes[nodeParentId];
    const nodeVParent = nodes[vBlockId];
    const nodeHParent = nodes[nodeHParentId];
    const onlyHBlockChild = nodeHParent.children.length === 1;
    const onlyVBlockChild = nodeVParent.children.length === 1;
    const onlyParentChild = nodeParent.children.length === 1;
    const isBlock =
      onlyHBlockChild &&
      onlyVBlockChild &&
      onlyParentChild &&
      nodeParentId === vBlockId;

    if (
      isBlock ||
      id === draggedNode ||
      draggedWrapper?.querySelector(".activeWrapper")
    ) {
      // Make sure it is not within itself
      document.querySelector(".activeBlock")?.classList.remove("activeBlock");
      document
        .querySelector(".activeWrapper")
        ?.classList.remove("activeWrapper");
      return;
    }
    wrapper?.classList.add("activeWrapper");
    setDestination(_id);
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();
    const target = document.querySelector(".activeWrapper");
    target?.classList.remove("activeWrapper");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    // setDestination(null);
  };

  useEffect(() => {
    const wrapper = ele.current;
    const editable = wrapper.querySelector("[contenteditable]");

    if (ele.current && node.new) {
      if (["image", "page"].includes(node.type)) {
        setUndo({ node, focusedId: id });
      } else {
        try {
          const selObj = rangy.getSelection();
          const _selection = rangy.serializeSelection(selObj, true, editable);
          setUndo(
            {
              node: { ...node, text: node.text, cursor: _selection },
              focusedId: id,
            },
            0
          );
        } catch (error) {}
      }
      updateNode(id, { new: null });
    }
  });

  return (
    <div
      ref={ele}
      data-id={id}
      // style={{ ...node?.styles, backgroundColor: focusedNode === id ? "#F3F3F3" : node.styles?.backgroundColor }}
      style={{ ...node?.styles }}
      className="nodeWrapper w-full px-0.5 rounded-sm flex flex-col justify-center"
      onMouseOver={handleMouseOver}
      // onMouseEnter={handleMouseOver}
      onMouseDown={handleMouseOver}
      // onMouseLeave={handleMouseLeave}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {children}
    </div>
  );
});

export default Wrapper;
