const H2 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-3{isolation:isolate}.cls-4{fill:#3c3b3b}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_13" data-name="Layer 13">
        <g id="Group_9078" data-name="Group 9078">
          <g id="Rectangle_40" data-name="Rectangle 40">
            <rect
              width={60}
              height={60}
              rx={4}
              style={{
                fill: "none",
              }}
            />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              style={{
                fill: "#9f9f9f",
              }}
            />
          </g>
          <g id="H2" className="cls-3">
            <g className="cls-3">
              <path
                d="M30.44 41h-5.11v-8.91h-7.58V41h-5.14V19.67h5.14v8.47h7.58v-8.47h5.11Z"
                className="cls-4"
              />
            </g>
            <g className="cls-3">
              <path
                d="M44.07 41h-9.92v-2.15l4.57-4.8A9.85 9.85 0 0 0 40 32.27a2.84 2.84 0 0 0 .41-1.27 2.18 2.18 0 0 0-.4-1.4 1.44 1.44 0 0 0-1.16-.48 1.38 1.38 0 0 0-.7.18 1.58 1.58 0 0 0-.53.47 2.23 2.23 0 0 0-.34.71 3.07 3.07 0 0 0-.12.88h-3.3a4.64 4.64 0 0 1 1.4-3.36 4.9 4.9 0 0 1 1.6-1 5.47 5.47 0 0 1 2.06-.37 7.24 7.24 0 0 1 2.08.27 4.32 4.32 0 0 1 1.5.81 3.3 3.3 0 0 1 .91 1.29 4.59 4.59 0 0 1 .31 1.75 4.24 4.24 0 0 1-.24 1.42 6.06 6.06 0 0 1-.67 1.35 11.23 11.23 0 0 1-1.07 1.38c-.43.47-.9 1-1.43 1.49l-1.84 2.12h5.6Z"
                className="cls-4"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default H2
