import { useDBStore } from "./stores/dbStore";

export function useLink() {
  const {
    setCurrentPage,
    initDB,
    history,
    setHistory,
    setPreviousPage,
    currentPage,
    updateNode,
    pages,
  } = useDBStore((state) => state);
  const navigate = (id, data = null) => {
    const $currentPage = pages[currentPage];
    const page = pages[id];
    if (page && page.title && page.title !== $currentPage.nodes[id]?.text) {
      updateNode(id, { text: page.title });
    }
    let title = $currentPage ? $currentPage.nodes[id]?.text : null;
    title = page?.title ?? title ?? "UntitledPage";
    const url = `/${title}-${id}`;
    setPreviousPage(currentPage);
    setCurrentPage(id);
    !page &&
      initDB({ id, title: $currentPage?.nodes[id]?.text ?? null, href: url });
    const i = history.indexOf(id);
    const _history = i < 0 ? history : history.slice(0, i);
    setHistory([..._history, id]);
    window.history.pushState(data, title, url);
  };
  return { navigateTo: navigate };
}
