import React from "react";
import Hamburger from "../components/top/Hamburger";
import Breadcrumb from "../components/top/Breadcrumb";
import RightSide from "../components/top/RightSide";

const Nav = () => {
  return (
    <div className="flex w-full min-h-[8vh] border-b *:align-middle">
      <Hamburger />
      <Breadcrumb />
      <RightSide />
    </div>
  );
};

export default Nav;
