const H1 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-3{isolation:isolate}.cls-4{fill:#3c3b3b}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_13" data-name="Layer 13">
        <g id="Group_9077" data-name="Group 9077">
          <g id="Rectangle_2446" data-name="Rectangle 2446">
            <rect
              width={60}
              height={60}
              rx={4}
              style={{
                fill: "none",
              }}
            />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              style={{
                fill: "#9f9f9f",
              }}
            />
          </g>
          <g id="H1" className="cls-3">
            <g className="cls-3">
              <path
                d="M30.44 41h-5.11v-8.91h-7.58V41h-5.14V19.67h5.14v8.47h7.58v-8.47h5.11Z"
                className="cls-4"
              />
            </g>
            <g className="cls-3">
              <path
                d="M41.44 41h-3.3V30.49l-3.24 1v-2.5l6.24-2.16h.3Z"
                className="cls-4"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default H1
