import { useEffect, useRef, useState } from "react";
import { SiIconify } from "react-icons/si";
import { IoIosClose } from "react-icons/io";
import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useDBStore } from "../../stores/dbStore";

const EditorTitle = () => {
  const {
    createNewBlock,
    fetchEmojis,
    emojis,
    currentPage,
    updatePage,
    handleKeyDowns,
    pages,
  } = useDBStore((state) => state);
  const [openDialog, setOpenDialog] = useState(false);
  const page = pages[currentPage];
  const [text, setText] = useState(null);
  const eleRef = useRef(null);
  const titleRef = useRef(null);

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const target = e.target;
      const selection = window.getSelection();
      let range = document.createRange();
      const startNode = selection.anchorNode;
      const startOffset = selection.anchorOffset;
      range.selectNodeContents(target);
      range.setStart(startNode, startOffset);
      selection.removeAllRanges();
      selection.addRange(range);
      range = selection.getRangeAt(0);
      const contents = range.extractContents();
      range.deleteContents();
      updatePage(currentPage, { title: target.textContent });
      const span = document.createElement("span");
      span.append(contents);
      createNewBlock("paragraph", 0, { text: span.textContent });
      span.remove();
    }
  };

  const handleSave = (e) => {
    const text = titleRef.current === "" ? null : eleRef.current.textContent;
    updatePage(currentPage, { title: text });
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain");
    document.execCommand('insertText', false, text)
  };

  useEffect(() => {
    if (!eleRef.current) {
      titleRef.current = page.title;
      const selection = window.getSelection();
      selection.selectAllChildren(eleRef.current);
      selection.collapseToEnd();
    }
    !emojis && fetchEmojis();
    eleRef.current.addEventListener("focusout", handleSave);

    return () => eleRef.current.removeEventListener("focusout", handleSave);
  }, [emojis]);

  return (
    <div className="flex flex-col *:w-full mb-5 px-2">
      <div className="hover:text-slate-500">
        <button
          onClick={() => setOpenDialog(!openDialog)}
          className="w-auto text-white h-[50px] rounded-lg px-3 my-1 flex justify-evenly items-center hover:text-slate-500 hover:bg-slate-50"
        >
          <SiIconify />
          <span className="pl-2">Add Icon</span>
        </button>
      </div>

      <Dialog
        open={openDialog}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={() => setOpenDialog(false)}
        __demoMode
      >
        <div className="fixed inset-0 z-10 w-screen">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="w-full h-[fit-content] bg-slate-100 mr-80 max-w-md rounded-xl py-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <DialogTitle
                as="div"
                className="text-base/7 pb-2 flex justify-between items-center font-medium text-gray-400 border-b border-b-neutral-300 px-6"
              >
                Add icon
                <IoIosClose size={24} onClick={() => setOpenDialog(false)} />
              </DialogTitle>
              <p className="mt-3 w-auto h-40 flex flex-wrap justify-stretch *:size-8 text-sm/6 text-black/50 ml-6 mr-4 overflow-y-scroll overflow-x-hidden">
                {emojis?.map(({ character }) => {
                  return (
                    <span
                      onClick={() =>
                        updatePage(currentPage, { icon: character })
                      }
                    >
                      {character}
                    </span>
                  );
                })}
              </p>
              <div className="flex justify-end mx-3 -mb-3">
                <Button
                  onClick={() => updatePage(currentPage, { icon: null })}
                  className="px-2 py-1 mt-2 bg-red-300 rounded-md text-gray-800"
                >
                  Remove
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <div className="flex justify-start">
        {page.icon && <span className="text-[2.5rem] mr-2">{page.icon}</span>}
        <span
          contentEditable={true}
          className="title font-bold select-none not-selectable w-full outline-none text-[2.5rem] whitespace-nowrap"
          aria-placeholder="Untitled"
          ref={eleRef}
          onChange={(e) => {
            titleRef.current = e.target.textContent;
          }}
          onPaste={handlePaste}
          onKeyUp={handleKeyUp}
          onKeyDown={(e) => {
            if (e.ctrlKey || e.shiftKey || e.metaKey || e.key === "Enter") {
              if (["i", "b", "u", "enter"].includes(e.key.toLowerCase())) {
                e.stopPropagation();
                e.preventDefault();
              }
              return;
            }
            // handleKeyDowns(e);
          }}
          dangerouslySetInnerHTML={{ __html: titleRef.current }}
        ></span>
      </div>
    </div>
  );
};

export default EditorTitle;
