import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";

const Callout = ({ id, renderer }) => {
  const { pages, currentPage, setDestination } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];

  const handleDragOver = (e) => {
    e.stopPropagation();
    const target = e.target.closest("[contenteditable]");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    target?.classList.remove("border-b");
    if (!target) return;
    target?.classList.add("border-b");
    setDestination(id);
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();
    const target = document.querySelector(".dd.border-b");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    target?.classList.remove("border-b");
  };

  return (
    <div
      key={id}
      className="flex-col w-full pl-6 pb-3 pr-3 bg-[#F1F1EF] rounded-lg"
    >
      {/* <div className="w-[40px] h-full mr-3">
        <IoBulbOutline className="size-[20px]" />
      </div> */}
      <div className="size-full">
        <ContentEditable
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          key={id}
          id={id}
          className="dd outline-none text-normal mt-1 pb-1 w-full min-h-6 inline-block border-b-gray-400"
          aria-placeholder="Type Something..."
        />
      </div>
      <div
        key={id + "2"}
        className={`flex-col ${node.children?.length > 0 ? "flex" : "hidden"}`}
      >
        {node.children?.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
      </div>
    </div>
  );
};

export default Callout;
