const BulletList = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-2{fill:#9f9f9f}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Heart">
        <g id="Group_9001" data-name="Group 9001">
          <g id="Rectangle_40-14" data-name="Rectangle 40-14">
            <rect
              width={60}
              height={60}
              rx={4}
              style={{
                fill: "none",
              }}
            />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              className="cls-2"
            />
          </g>
          <g id="Group_9000" data-name="Group 9000">
            <path
              id="Line_846-5"
              d="M51 25H27a1 1 0 0 1 0-2h24a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 846-5"
            />
            <path
              id="Line_847-5"
              d="M42 31H27a1 1 0 0 1 0-2h15a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 847-5"
            />
            <path
              id="Line_855-4"
              d="M45 37H27a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 855-4"
            />
            <circle
              id="Ellipse_979-3"
              cx={14}
              cy={29}
              r={5}
              data-name="Ellipse 979-3"
              style={{
                fill: "#3c3b3b",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default BulletList;
