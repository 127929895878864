import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDBStore } from "../../stores/dbStore";
import { Selection, rangy } from "../../DOM";
import { getAbsolutePosition } from "../../helpers";
// import { sanitize } from "../../helpers";

const ContentEditable = memo(({ id, ...otherProps }) => {
  const inputRef = useRef(null);
  const [html, setHtml] = useState(null);
  // const [typing, setTyping] = useState(null);
  const [selection, setSelection] = useState(null);
  const [editable, setEditable] = useState(false);
  const {
    pages,
    currentPage,
    handleKeyDowns,
    handleKeyUps,
    updateNode,
    save,
    setUndo,
    setFocus,
    isEditable,
    setActiveNode,
    setShowInlineTool,
    setTimer,
    handlePaste,
  } = useDBStore((state) => state);
  const { nodes, focusedNode, cursor, undos } = pages[currentPage];
  const node = nodes[id];
  const lastUndoDb = undos.slice(-1)[0];
  const _node = lastUndoDb ? lastUndoDb[id] : null;
  const editor = document.querySelector("#editor");

  const handleInput = (e) => {
    const selObj = rangy.getSelection();
    const _selection = rangy.serializeSelection(selObj, true, inputRef.current);
    save({
      node: {
        ...node,
        cursor: _selection,
        text: e.currentTarget.innerHTML,
      },
      focusedId: id,
    });
  };

  const handleSave = (e) => {
    try {
      const selObj = rangy.getSelection();
      const selection = rangy.serializeSelection(
        selObj,
        true,
        inputRef.current
      );
      updateNode(id, { text: e.target.innerHTML, cursor: selection });
    } catch (error) {}
  };

  const handleMouseDown = (e) => {
    e.stopPropagation();
    e.target.closest("#editor")?.setAttribute("contenteditable", true);
    // inputRef.current?.setAttribute("contenteditable", false);
    setShowInlineTool(null);
  };

  useEffect(() => {
    if (inputRef.current && focusedNode === id) {
      const $this = inputRef.current;
      $this.focus();
      let selection = null;
      let subject;
      if (node.cursor) {
        subject = inputRef.current;
        selection = node.cursor;
      } else {
        subject = editor;
        selection = cursor;
      }
      try {
        rangy.deserializeSelection(selection, subject);
      } catch (error) {
        // console.log(error);
        const Selection = window.getSelection();
        const Range = document.createRange();
        Range.selectNodeContents(inputRef.current);
        Range.collapse(false);
        Selection.removeAllRanges();
        Selection.addRange(Range);
      }
    }
    inputRef.current.addEventListener("focusin", () => {
      setActiveNode(id);
      setFocus(id);
    });
  }, [cursor, node]);

  return (
    <span
      ref={inputRef}
      contentEditable="true"
      tabIndex={0}
      onInput={handleInput}
      onKeyDown={handleKeyDowns}
      onPaste={handlePaste}
      onMouseDown={handleMouseDown}
      onKeyUp={(e) => {
        setTimer(e, handleSave, 500);
        handleKeyUps(e);
      }}
      // onMouseLeave={handleSave}
      dangerouslySetInnerHTML={{ __html: node.text ?? "" }}
      {...otherProps}
    ></span>
  );
});

export default ContentEditable;
