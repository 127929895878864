const Toggle = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-2{fill:#9f9f9f}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Heart">
        <g id="Group_8983" data-name="Group 8983">
          <g id="Rectangle_40-7" data-name="Rectangle 40-7">
            <rect
              width={60}
              height={60}
              rx={4}
              style={{
                fill: "none",
              }}
            />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              className="cls-2"
            />
          </g>
          <g id="Group_8982" data-name="Group 8982">
            <path
              id="icon46"
              d="m23.39 23.56-9.54 5.37a1.23 1.23 0 0 1-1.68-.44 1.3 1.3 0 0 1-.17-.58V17.18A1.24 1.24 0 0 1 13.27 16a1.3 1.3 0 0 1 .58.17l9.54 5.37a1.15 1.15 0 0 1 0 2Z"
              style={{
                fill: "#3c3b3b",
              }}
            />
            <path
              id="Line_846"
              d="M48 24H33a1 1 0 0 1 0-2h15a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 846"
            />
            <path
              id="Line_847"
              d="M48 37H12a1 1 0 0 1 0-2h36a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 847"
            />
            <path
              id="Line_848"
              d="M48 45H12a1 1 0 0 1 0-2h36a1 1 0 0 1 0 2Z"
              className="cls-2"
              data-name="Line 848"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Toggle;
