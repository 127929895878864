import React, { useEffect, useRef } from "react";
import { IoDocumentOutline } from "react-icons/io5";
import { useDBStore } from "../../stores/dbStore";
import { useLink } from "../../hooks";

const Page = ({ id }) => {
  const { pages, currentPage, setShowMenuButtons } = useDBStore(
    (state) => state
  );
  const $this = useRef(null);
  const { nodes } = pages[currentPage];
  const { navigateTo } = useLink();
  const page = pages[id];
  const node = nodes[id];
  useEffect(() => {
    if (!page && !node.text && $this.current) {
      setShowMenuButtons(null);
      setTimeout(() => {
        navigateTo(id);
      }, 800);
    }
  }, []);
  return (
    <div
      ref={$this}
      className="w-full min-h-5 underline-offset-4 text-gray-400 underline flex hover:text-gray-900 hover:font-semibold"
    >
      {page?.icon ?? <IoDocumentOutline />} {"  "}
      <button
        onClick={() => {
          setShowMenuButtons(null);
          navigateTo(id ?? "#");
        }}
        className="text-left mx-4 text-gray-600"
      >
        {page?.title ?? node?.text ?? "UntitledPage"}
      </button>
    </div>
  );
};

export default Page;
