const Quote = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <style>{".cls-1{fill:none}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Heart">
        <g id="Group_8901" data-name="Group 8901">
          <g id="Rectangle_40-4" data-name="Rectangle 40-4">
            <rect width={60} height={60} className="cls-1" rx={4} />
            <path
              d="M56 60H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h52a4 4 0 0 1 4 4v52a4 4 0 0 1-4 4ZM4 .5A3.5 3.5 0 0 0 .5 4v52A3.5 3.5 0 0 0 4 59.5h52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 56 .5Z"
              style={{
                fill: "#9f9f9f",
              }}
            />
          </g>
          <g id="Group_8900" data-name="Group 8900">
            <path
              id="Path_8710"
              d="M25 28.05a7 7 0 0 0-2-.31h-.7c.6-1.6 1.61-3.46 3.12-3.69a1.62 1.62 0 0 0 1.29-1.2l.56-2.15a1.75 1.75 0 0 0-.14-1.27 1.61 1.61 0 0 0-1.13-.8c-3.75-.54-7.63 3.34-9.14 9.08-.82 3.11-1 7.83 1 10.86a6.45 6.45 0 0 0 5.37 2.85 6.17 6.17 0 0 0 4.54-2 6.9 6.9 0 0 0 1.88-4.82A6.77 6.77 0 0 0 25 28.05ZM26.44 38a4.32 4.32 0 0 1-3.17 1.4 4.54 4.54 0 0 1-3.83-2c-1.58-2.29-1.51-6.23-.75-9.12 1.17-4.43 3.94-7.64 6.58-7.64h.05l-.4 1.5c-3 .64-4.41 4.54-5 6.84a1.08 1.08 0 0 0 .3 1 .92.92 0 0 0 1 .16 4 4 0 0 1 1.78-.37 5.11 5.11 0 0 1 1.46.22 4.76 4.76 0 0 1 3.29 4.6A4.9 4.9 0 0 1 26.44 38Zm16.48-6.72a6.49 6.49 0 0 0-3.85-3.21 7.12 7.12 0 0 0-2-.31 5.48 5.48 0 0 0-.7 0c.6-1.6 1.61-3.45 3.12-3.69a1.6 1.6 0 0 0 1.28-1.2l.57-2.15a1.68 1.68 0 0 0-1.09-2.06l-.21-.05c-3.75-.54-7.63 3.35-9.14 9.08-.82 3.11-1 7.83 1 10.86a6.48 6.48 0 0 0 5.37 2.86 6.21 6.21 0 0 0 4.54-2 6.93 6.93 0 0 0 1.88-4.82 7.12 7.12 0 0 0-.77-3.33ZM40.51 38a4.32 4.32 0 0 1-3.17 1.4 4.54 4.54 0 0 1-3.83-2c-1.58-2.29-1.51-6.23-.75-9.12 1.17-4.43 3.94-7.64 6.58-7.64l-.4 1.5c-3 .65-4.41 4.55-5 6.85a1.07 1.07 0 0 0 .3 1 .92.92 0 0 0 1 .17 4.2 4.2 0 0 1 1.78-.39 5.18 5.18 0 0 1 1.46.23 4.54 4.54 0 0 1 2.71 2.26 5 5 0 0 1 .58 2.34 4.89 4.89 0 0 1-1.26 3.4Z"
              data-name="Path 8710"
              style={{
                fill: "#3c3b3b",
              }}
            />
            <path
              id="Rectangle_2428"
              d="M15 15h30v30H15z"
              className="cls-1"
              data-name="Rectangle 2428"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Quote;
